import React, { Component } from "react";
import {
    Card,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Nav,
    Row,
    Form,
    Alert
} from 'react-bootstrap';
import './style.css';

import DepartementService from '../../services/DepartementService';
import CommuneService from '../../services/CommuneService';
import ArrondissementService from '../../services/ArrondissementService';
import VillageService from '../../services/VillageService';
import {getEntity} from '../../services/api';

import Select from "react-select";


export default class ZoneDemande extends Component {

    state = {
        dep: [],
        com: [],
        arr: [],
        vil: [],
        errors : ''

    }
    

    componentDidMount() {
      this.loadDepartement();
    }

    loadCommunesFromDepartement = code =>{

         getEntity(`communes?all&filter[departement_code][op]=like&filter[departement_code][value]=${code}`).then(res=>{
           
                this.setState({com: res.data.data })
            })
    }
    

    loadArrondissementsFromCommune = code =>{
        
        getEntity(`arrondissements?all&filter[commune_code][op]=like&filter[commune_code][value]=${code}`).then(res=>{
              this.setState({arr: res.data.data })
                
            })
    }

    loadVillagesFromArrondissement = code =>{

         getEntity(`villages?all&filter[arrondissement_code][op]=like&filter[arrondissement_code][value]=${code}`).then(res=>{
                this.setState({vil: res.data.data })
                
            })
              
    }


    onSelectDepartement = event => {
        this.props.changeState('departement',this.state.dep.filter(d=>d.code==event.target.value)[0].name)
        this.loadCommunesFromDepartement(event.target.value);
    }

     onSelectCommune = event =>{

        this.props.changeState('commune',this.state.com.filter(c=>c.code==event.target.value)[0].name)
        this.loadArrondissementsFromCommune(event.target.value);
    }


    onSelectArrondissement = event =>{

        this.props.changeState('arrondissement',this.state.arr.filter(a=>a.code==event.target.value)[0].name)
        this.loadVillagesFromArrondissement(event.target.value);

    }


    loadDepartement = async () => {
        const res = await DepartementService.getAll();
        this.setState({dep:res.data.data});
    }


    continue = e => {
        e.preventDefault();

        this.checkZone();
    }

    back = e => {
        e.preventDefault();
        this.props.prevStep();
    }

   checkZone = () => {
    const { values } = this.props;

    const {errors} = this.state;

    if(!values.departement || !values.commune || !values.arrondissement || !values.quartier){
      this.setState({errors : "Required"});
    }else{
        this.setState({errors : ""});
       this.props.nextStep(); 
    }
  }

    render() {

        const {dep, com, arr, vil, errors} = this.state;

        const { values } = this.props;

        return (
            <div>
             { errors == "Required" &&
                <Alert variant="warning">
                Veuillez renseignez le ou les champs portant l'étoile(*)
                    </Alert>
                }

                <Row>
                    <Col md={3} sm={12} xs={10}>
                    
                        <Form.Group >
                            <Form.Label>Département <abbr title="Ce champs est requis">*</abbr></Form.Label>

                            <Form.Control as="select"  size="lg"  required="required" name="departement" 
                                // value={values.departement} 
                                defaultValue={values.departement}
                                onChange={this.onSelectDepartement}>
                                    <option selected disabled value="Choisir un departement">Choisir un departement</option>
                                {
                                    
                                    dep.map( (dep, index )=>(<option key={index} value={dep.code}>{dep.name}</option>))
                                }
                                 
                                
                            </Form.Control>
    
                        </Form.Group>
                    </Col>
                    <Col className="ml-5" md={3} sm={12} xs={10}>
                        <Form.Group >
                            <Form.Label>Commune <abbr title="Ce champs est requis">*</abbr></Form.Label>
                            <Form.Control as="select" size="lg"  required="required" name="commune"
                            //  value={values.commune}
                            defaultValue={values.commune}
                               onChange={this.onSelectCommune}
                               >
                                   <option selected disabled value="Choisir une Commune">Choisir une Commune</option>
                                {
                                    com.map( (com, index )=>(<option key={index} value={com.code}>{com.name}</option>))
                                }
                                
                                
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="ml-5" md={3} sm={12} xs={10}>
                        <Form.Group >
                            <Form.Label>Arrondissement <abbr title="Ce champs est requis">*</abbr></Form.Label>
                            <Form.Control as="select" size="lg" required="required" name="arrondissement"
                             
                              defaultValue={values.arrondissement}
                                onChange={this.onSelectArrondissement}>
                                    <option selected disabled value="Choisir un arrondissement">Choisir un arrondissement</option>
                               {
                                    arr.map( (a, index) =>(<option key={index} value={a.code} >{a.name}</option>))
                                }
                                value={values.arrondissement}
                            </Form.Control>

                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={3} sm={12} xs={10}>
                        <Form.Group >
                            <Form.Label>Village/quartier <abbr title="Ce champs est requis">*</abbr></Form.Label>
                            <Form.Control as="select" size="lg" placeholder="village" required="required"
                             
                              defaultValue={values.quartier}
                               name="quartier"
                                onChange={this.props.handleChange('quartier')}>
                                    <option selected disabled value="Choisir un quartier">Choisir un quartier</option>
                                 {
                                    vil.map( (v, index) =>(<option key={index}  value={v.code} >{v.nom}</option>))
                                }
                                value={values.quartier}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col className="ml-5" md={3} sm={12} xs={10}>
                        <Form.Group >
                            <Form.Label>Rue</Form.Label>
                            <Form.Control size="lg" type="text" placeholder="rue" required="required" value={values.rue} defaultValue={values.rue} name="rue" onChange={this.props.handleChange('rue')} />
                        </Form.Group>
                    </Col>
                    <Col className="ml-5" md={3} sm={12} xs={10}>
                        <Form.Group>
                            <Form.Label>Lot</Form.Label>
                            <Form.Control size="lg" type="text" placeholder="lot" required="required" value={values.lot} defaultValue={values.lot} name="lot" onChange={this.props.handleChange('lot')} />
                        </Form.Group>
                    </Col>
                </Row>

                <p>
                    <button type="button" className="btn btn-outline-primary btn-lg" onClick={this.back} ><i className="fa fa-angle-left" aria-hidden="true"></i> Retour</button>
                    <button type="button" className="btn btn-primary btn-lg ml-3" onClick={this.continue} > Suivant <i className="fa fa-angle-right" aria-hidden="true"></i></button>
                </p>
            </div>
        );
    }
};
