import React, { useEffect, useState } from 'react';
import VillageService from '../../services/VillageService';
import ListingWithFilter from '../ListingWithFilter';

export default function VillageSelectorTab({handleVillageSelected, query}) {
  const [villages, setVillages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadVillages();
  }, []);

  const loadVillages = async () => {
    const res = await VillageService.getAll();
    setVillages(res.data.data);
    setLoading(false);
  }

  return (
    <ListingWithFilter data={villages} handleItemSelected={handleVillageSelected} query={query} labelField='nom' loading={loading}></ListingWithFilter>
  );
}
