import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import {
  GoogleApiWrapper,
  InfoWindow,
  Map,
  Marker,
  Polygon
} from 'google-maps-react';

const wrapperStyle = {
  position: 'relative',
  width: '100%',
  height: '700px',
  paddingTop: '50%',
};

const innerWrapperStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0
};

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%'
};

const mapStyle = [
    {
      elementType: 'labels.icon',
      stylers: [{visibility: 'off'}]
    },
]

export function Mapp({google, data, markeurImage, legendData, indicateur}) {
  const [activeMarker, setActiveMarker] = useState({});
  const [activeMarkerData, setActiveMarkerData] = useState({});
  const [showingInfoWindow, setShowingInfoWindow] = useState(false);
  const [tooltipValue, setTooltipValue] = useState('');
  const [tooltipStyle, setTooltipStyle] = useState({});

  function onMarkerClick(props, marker, e, data) {
    setActiveMarker(marker);
    setActiveMarkerData(data);
    setShowingInfoWindow(true);
  }

  function onMapClicked() {
    if (showingInfoWindow) {
      setShowingInfoWindow(false);
      setActiveMarker({marker: {}, data: {}})
    }
  }

  function mapLoaded(mapProps, map) {
    map.setOptions({
       styles: mapStyle
    });

    drawLegend(map);
  }

  function fromLatLngToPoint(latLng, map) {
    var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
    var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
    var scale = Math.pow(2, map.getZoom());
    var worldPoint = map.getProjection().fromLatLngToPoint(latLng);
    return new google.maps.Point((worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale);
  }

  function onPolygonHover(item, polygon, e) {
    const pos = fromLatLngToPoint(e.latLng, polygon.map);
    setTooltipStyle({top: pos.y + 'px', left: pos.x + 'px'});
    setTooltipValue(item.key + ' (' + item.value + ')');
  }

  const legendRef = React.createRef();
  function drawLegend(map) {
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(legendRef.current);
  }

  function mapGeometry(item, index) {
    switch (item.geo.type) {
      case 'Point':
        return <Marker
          position={{
            lat: item.geo.coordinates[1],
            lng: item.geo.coordinates[0]
          }}
          key={index}
          title={item.valeur}
          name={item.valeur}
          icon={markeurImage ? {
            url: markeurImage,
            scaledSize: new google.maps.Size(37.5, 56),
            origin: new google.maps.Point(0 ,0),
            anchor: new google.maps.Point(18.75, 56)
          } : null}
          onClick={(props, marker, e) => onMarkerClick(props, marker, e, item)}
        >
          <span style={{backgroundColor: item.couleur}}>
            {item.valeur}
          </span>
        </Marker>;
        break;
        case 'MultiPolygon':
          let paths = []
          item.geo.coordinates[0].forEach((a) => {
            paths = paths.concat(a.map(c => {
              return {lng: c[0], lat: c[1]};
            }));
          });
          return <Polygon
            key={index}
            paths={paths}
            strokeColor="#0000FF"
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor={item.couleur}
            fillOpacity={0.6}
            onMouseover={(_, polygon, e) => onPolygonHover(item, polygon, e)}
          />
    }
  }

  return (
    <div style={wrapperStyle}>
      <div style={innerWrapperStyle}>
        <Map
          google={google}
          zoom={6}
          containerStyle={containerStyle}
          initialCenter={{
            lat: 9.5293472,
            lng: 2.2584408,
          }}
          onClick={onMapClicked}
          onReady={mapLoaded}
        >
          {data.map(mapGeometry)}

          <InfoWindow
            google={google}
            marker={activeMarker}
            visible={showingInfoWindow}>
            <h6 style={{marginBottom: 0}}>{activeMarkerData.value} {indicateur.unite ? indicateur.unite : ''}</h6>
          </InfoWindow>
        </Map>

        <div className={classnames('tooltip bs-tooltip-top', {show: tooltipValue !== ''})} role="tooltip" style={tooltipStyle}>
          <div className="tooltip-inner">
            {tooltipValue}
          </div>
        </div>
      </div>

      <ul ref={legendRef} className="map-legend">
        {
          legendData && legendData.map((d, k) => {
            return <li key={k}>
              <span style={{backgroundColor: d.color}}></span>
              {d.min} - {d.max}
            </li>
          })
        }
      </ul>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyCo-Twdg-fAUZm3f7aBeJQDkCU2bQfLiVQ'
})(Mapp)
