import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import classnames from 'classnames';
import IndicateurService from '../../services/IndicateurService';

export default function IndicateursCles({query, handleIndicateurSelected}) {
  const [loading, setLoading] = useState(true);
  const [thematiques, setThematiques] = useState([]);
  const [filteredThematiques, setFilteredThematiques] = useState([])

  useEffect(() => {
    loadIndicateurs();
  }, []);

  const loadIndicateurs = async () => {
    const res = await IndicateurService.getMains();
    const data = res.data.data

    let thems = []

    for (let indicateur of data) {
      let thematique = thems.find(them => them.id == indicateur.thematique_id)

      if(!thematique) {
        thematique = indicateur.thematique
        thematique.indicateurs = []
        thems.push(thematique)
      }

      thematique.indicateurs.push(indicateur)
    }

    thems = thems.sort((a, b) => a.nom.localeCompare(b.nom))

    setThematiques(thems);
    setLoading(false);
  }

  useEffect(() => {
    filterItems()
  }, [thematiques, query]);

  const filterItems = () => {
    if (!query) {
      setFilteredThematiques(thematiques);
      return
    }

    const queryItems = thematiques.map(thematique => {
      return {
        ...thematique,
        indicateurs: thematique.indicateurs.filter(item => item.nom.toLowerCase().includes(query))
      }
    })

    setFilteredThematiques(queryItems);
  }

  return (
    <div className={classnames({'d-flex justify-content-center align-items-center': loading})}>
      <i className={classnames('fas fa-3x fa-circle-notch', { 'fa-spin': loading, 'd-none': !loading })}></i>
      {filteredThematiques.map(thematique => (thematique.indicateurs.length > 0 && (
          <div key={thematique.id} className="my-5">
            <h3>{thematique.nom}</h3>
            <Row className="thematique-indicateurs">
              {thematique.indicateurs.map(indicateur => (
                <Col md={6} key={indicateur.id} className='indicateur my-2 pr-5'>
                  <Button variant="link" className="item my-0 px-0 text-left text-decoration-none font-weight-bold" key={indicateur.id}
                    onClick={() => handleIndicateurSelected(indicateur)}>{indicateur.nom}</Button>
                </Col>
              ))}
            </Row>
          </div>
        )
      ))}
    </div>
  );
}
