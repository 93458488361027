import React from 'react';
import BackToTop from '../components/BackToTop';
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  Row
} from 'react-bootstrap';
import * as moment from 'moment';
import { HomeBannerSingle } from '../components/home/BannerSingle';
import { Link } from 'react-router-dom';
import AreaSelector from '../components/area-selector/AreaSelector';
import { Bar, Line } from 'react-chartjs-2';
import Mapp from '../components/Maps/Map';
import { getQueryParameters } from '../utils';
import { post } from '../services/api';
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import classnames from 'classnames';
import { ICONES_IMAGES } from '../constants/icon';
import { minBy as _minBy, maxBy as _maxBy } from 'lodash';
import ChartService from '../services/ChartService';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import * as queryString from 'query-string';
import ReactGA from 'react-ga';


export class Analyse extends React.Component {
  state = {
    panel: 'line',
    queryParams: {},
    rawData: [],
    tableData: [],
    tableColumns: [],
    barChartData: {},
    barChartOptions: {},
    mapData: [],
    mapDataLoaded: false,
    title: '',
    defaultStartYear: 0,
    defaultEndYear: 0,
    startYear: 0,
    endYear: 0,
    selectedYear: 0,
    indicateur: null,
    mapGrouping: null,
    chartGrouping: null,
    loading: false,
    legendData: null
  };
  timeoutid = null;
  icones = ICONES_IMAGES['Bleu']['PNG'];

  constructor() {
    super();
    this.onDatesRangeChange = this.onDatesRangeChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.setMapGrouping = this.setMapGrouping.bind(this);
    this.setChartGrouping = this.setChartGrouping.bind(this);
  }

  selectPanel(panel) {
    this.setState({panel}, () => {
      this.loadData();
      this.tackerEventAnalyse(panel);
    });
  }

   tackerEventAnalyse =(panel)=>{
    ReactGA.event({
      category: 'Analyse',
      action: ' Type de visualisation : ' + panel + ', Indicateur : ' + this.state.indicateur.nom + 'Zone : '
      });
  }

  shouldUpdateChart(newQueryParams) {
    return JSON.stringify(newQueryParams) !== JSON.stringify(this.state.queryParams);
  }

  componentDidMount() {
    const queryParams = getQueryParameters(this.props.location.search);
    if (this.shouldUpdateChart(queryParams)) {
      this.loadData(queryParams);
    }
  }

  componentDidUpdate() {
    document.getElementById('analysePage').scrollIntoView();

    const queryParams = getQueryParameters(this.props.location.search);
    if (this.shouldUpdateChart(queryParams)) {
      this.loadData(queryParams);
    }
  }

  buildQuery(params = null) {
    if (!params) {
      params = this.state.queryParams;
    } else {
      this.setState({queryParams: params});
    }

    const requestParams = {
      indicateur_id: params.indicateur,
      area_ids: [params.area_id],
      area_type: params.area_type,
      type: this.state.panel,
    };

    if (this.state.panel === 'map') {
      requestParams.year = this.state.selectedYear;
      requestParams.group_by = this.state.mapGrouping;
    } else {
      requestParams.group_by = this.state.chartGrouping;
      if (this.state.startYear && this.state.endYear) {
        requestParams.date_from = moment().utc().year(this.state.startYear).startOf('year');
        requestParams.date_to = moment().utc().year(this.state.endYear).endOf('year');
      }
    }

    return requestParams;
  }

  async loadData(params = null) {
    this.setState({ loading: true });
    const requestParams = this.buildQuery(params);

    let response = await post('analyse', {
      data: requestParams
    });

    if (this.state.defaultStartYear === 0) {
      const year = this.getMinimumRangeYear(response.data);
      this.setState({defaultStartYear: year, startYear: year});
    }

    if (this.state.defaultEndYear === 0) {
      const year = this.getMaximumRangeYear(response.data);
      this.setState({defaultEndYear: year, endYear: year});
    }

    const indicateur = response.data.indicateur;
    const title = indicateur.unite ? `${indicateur.nom} (Unité : ${indicateur.unite})` : `${indicateur.nom}`;
    this.setState({indicateur});

    if (this.state.panel === 'map') {
      const mapData = response.data.data;
      this.setState({
        rawData : response.data.data,
        mapData,
        title,
        mapDataLoaded: true,
        legendData: response.data.legends,
      });
    } else {
      const barChartData = ChartService.toBarData(response.data.data, title, this.state.chartGrouping);
      let tableData = ChartService.getTableData(response.data.data, this.state.panel, this.state.mapGrouping);
      let tableColumns = ChartService.getTableColumns(tableData, this.state.panel, this.state.chartGrouping, this.state.mapGrouping, indicateur);

      const label = (item) => {
        return indicateur.unite ? `${item.yLabel} ${indicateur.unite}` : `${item.yLabel}`;
      };

      const barChartOptions = {
        tooltips: {
          callbacks: {label},
        },
      };
      this.setState({
        rawData : response.data.data,
        tableData: response.data.data,
        tableColumns,
        barChartData,
        barChartOptions,
        title
      });
    }


    this.setState({ loading: false });
  }

  getMinimumRangeYear(response) {
    let min = _minBy(response.data, (d) => d.key);
    if (min) {
      min = min.key;
    } else {
      min = response.indicateur.date_depart;
    }

    return (new Date(min)).getFullYear();
  }

  getMaximumRangeYear(response) {
    let max = _maxBy(response.data, (d) => d.key);
    if (max) {
      max = max.key;
    } else {
      max = response.indicateur.date_fin;
    }

    return (new Date(max)).getFullYear();
  }

  onDatesRangeChange(values) {
    if (values[0] === values[1]) {
      return;
    }
    this.setState({startYear: values[0], endYear: values[1]});

    if (this.timeoutid) {
      clearTimeout(this.timeoutid);
    }

    this.timeoutid = setTimeout(() => {
      this.loadData();
    }, 300);
  }

  onDateChange(value) {
    const previousValue = this.state.selectedYear;
    this.setState({selectedYear: value});

    if (this.timeoutid) {
      clearTimeout(this.timeoutid);
    }

    this.timeoutid = setTimeout(() => {
      this.loadData();
    }, 300);
  }

  setMapGrouping(e) {
    let value = e.target.value;
    if (value === 'details') {
      value = null;
    }

    this.setState({mapGrouping: value}, () => {
      this.loadData();
    })
  }

  setChartGrouping(e) {
    const value = e.target.value;
    this.setState({chartGrouping: value}, () => {
      this.loadData();
    })
  }

  downloadData(format) {
    const query = this.buildQuery();
    query.download_format = format;
    const actualLocation = window.location.href;
    const url = process.env.REACT_APP_API_BASE_URL
      + '/analyse/download?'
      + queryString.stringify(query, {arrayFormat: 'bracket'});

    window.open(url, '_blank');
    window.location.href = actualLocation;
    // window.history.pushState({path: actualLocation},'', actualLocation);
  }

  render() {
    return (
      <div>
        <HomeBannerSingle/>

        <Container className="mt-5" id="analysePage">
          <h3 className="mb-4">Analyse de données</h3>


          <div className={classnames('ressources-content', {'d-flex justify-content-center align-items-center': this.state.loading})}>
            <i className={classnames('fas fa-3x fa-circle-notch', { 'fa-spin': this.state.loading, 'd-none': !this.state.loading })}></i>
            <Row className={classnames({ 'd-none': !this.state.loading })}>
              <Col md={9}>
                <div className="text-center my-5 text-muted">
                  <h5>Aucune données disponible !</h5>
                </div>
              </Col>
            </Row>

            <Row className={classnames({ 'd-none': this.state.loading })}>
              <Col md={9}>
                <Row>
                  <Col>
                    <Card className="shadow rounded">
                      <Card.Header>
                        <Nav defaultActiveKey="line" className="custom-navs justify-content-center">
                          <Nav.Item>
                            <Nav.Link eventKey="line" onClick={() => this.selectPanel('line')} className="fontStyle">Linéaire</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="bar" onClick={() => this.selectPanel('bar')} className="fontStyle ml-5">Histogramme</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="map" onClick={() => this.selectPanel('map')} className="fontStyle ml-5">Carte</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="table" onClick={() => this.selectPanel('table')} className="fontStyle ml-5">Tableau</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Card.Header>
                      <Card.Body>
                        {this.state.panel === 'line' &&
                        <Line data={this.state.barChartData} options={this.state.barChartOptions}/>}
                        {this.state.panel === 'bar' &&
                        <Bar data={this.state.barChartData} options={this.state.barChartOptions}/>}
                        {this.state.panel === 'map' &&
                        <Mapp data={this.state.mapData}
                          markeurImage={this.state.indicateur ? this.icones[this.state.indicateur.icone] : null}
                          legendData={this.state.legendData}
                          indicateur={this.state.indicateur}/>}
                        {this.state.panel === 'table' &&
                        <BootstrapTable keyField='key' data={this.state.tableData} columns={this.state.tableColumns} pagination={paginationFactory()}/>
                        }
                      </Card.Body>
                      <Card.Footer>
                        <Row className={classnames('align-items-center  no-gutters', {'d-none': this.state.panel === 'map'})}>
                          <Col className="col-2">
                            <strong>{this.state.startYear} - {this.state.endYear} </strong>
                          </Col>
                          <Col className="col-6">
                            <Range value={[this.state.startYear, this.state.endYear]}
                              min={this.state.defaultStartYear} max={this.state.defaultEndYear}
                              onChange={this.onDatesRangeChange}/>
                          </Col>
                          <Col>
                            <div className="form-inline justify-content-end">
                              <label>Grouper par: </label> &nbsp;&nbsp;
                              <select defaultValue="year" className="custom-select" style={{width: '130px'}} onChange={this.setChartGrouping}>
                                <option value="year">Année</option>
                                <option value="month">Mois</option>
                                <option value="day">Jour</option>
                                <option value="departements">Départements</option>
                                <option value="communes">Communes</option>
                                <option value="arrondissements">Arrondissements</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                        <Row className={classnames('align-items-center no-gutters', {'d-none': this.state.panel !== 'map'})}>
                          <Col className="col-1">
                            <strong>{this.state.selectedYear} </strong>
                          </Col>
                          <Col className="col-6">
                            <Slider value={this.state.selectedYear}
                              min={this.state.defaultStartYear} max={this.state.defaultEndYear}
                              onChange={this.onDateChange}/>
                          </Col>
                          <Col className="col-1 text-right">
                            <strong>{this.state.defaultEndYear} </strong>
                          </Col>
                          <Col>
                            <div className="form-inline justify-content-end">
                              <label>Grouper: </label> &nbsp;&nbsp;
                              <select defaultValue="details" className="custom-select" style={{width: '130px'}} onChange={this.setMapGrouping}>
                                <option value="details">Affichage détaillé</option>
                                <option value="departements">Par départements</option>
                                <option value="communes">Par communes</option>
                                <option value="arrondissements">Par arrondissements</option>
                              </select>
                            </div>
                          </Col>
                        </Row>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
              </Col>
              <Col md={3}>
                <Card className="shadow rounded">
                  <Card.Body>
                    <Card.Title>
                      <i className="fas fa-download"></i> Télécharger les données
                    </Card.Title>
                  </Card.Body>
                  <ListGroup className="list-group-flush">
                    <ListGroupItem>
                      <Link to="#" onClick={() => this.downloadData('pdf')}><i className="fas fa-file-pdf"></i> PDF</Link>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Link to="#" onClick={() => this.downloadData('csv')}><i className="fas fa-file-csv"></i> CSV</Link>
                    </ListGroupItem>
                    <ListGroupItem>
                      <Link to="#" onClick={() => this.downloadData('xlsx')}><i className="fas fa-file-excel"></i> EXCEL</Link>
                    </ListGroupItem>
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </div>
          <div className="my-5">
            <h2 className="my-3">Les départements, communes, arrondissements et villages</h2>
            <AreaSelector></AreaSelector>
          </div>
        </Container>
        <BackToTop/>
      </div>
    );
  }
};
