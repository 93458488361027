import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import classnames from "classnames";
import { Link } from "react-router-dom";

import PartenaireService from "../../services/PartenaireService";

export class HomePartners extends React.Component {
  state = {
    partenaires: [],
    loading: true
  }

  componentDidMount() {
    this.loadPartenaires();
  }

  async loadPartenaires() {
    const res1 = await PartenaireService.getType('structure_centrale');
    const res2 = await PartenaireService.getType('acteur_eau');
    const partenaires = res1.data.data.concat(res2.data.data);

    this.setState({ partenaires, loading: false });
  }

  render() {
    return (
      <div className="home-more-partners">
        <Container>
          <Row className="no-gutters">
            <Col md={12}>
              <h2 className="title-bordered white to-left mb-5">
                <Link className="text-white text-decoration-none" to="/partenaires">
                  Nos partenaires <i className="fas fa-external-link-alt fa-xs"></i>
                </Link>
              </h2>
              <div className={classnames('ressources-content', { 'd-flex justify-content-center align-items-center': this.state.loading })}>
                <i className={classnames('fas fa-3x fa-circle-notch text-white', { 'fa-spin': this.state.loading, 'd-none': !this.state.loading })}></i>
                <Row className={classnames('justify-content-center', { 'd-none': this.state.loading })}>
                  {this.state.partenaires.map((partenaire) => (
                    <Col md={4} key={partenaire.id}>
                      <Card>
                        <Card.Img variant="top" src={(partenaire.logo || {}).url} />
                        <Card.Body>
                          <Card.Title className="title-bordered to-left title-w100 title-light">{partenaire.name}</Card.Title>
                          <Card.Text>
                            <span dangerouslySetInnerHTML={{ __html: partenaire.description }}></span>
                          </Card.Text>
                          <a href={partenaire.link} target="_blank" className="stretched-link"></a>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}

                  <Col md={4}>
                    <Card>
                      <Card.Body className="d-flex align-items-center justify-content-center">
                        <Card.Title className="title-light">Voir tous les partenaires <i className="fas fa-external-link-alt"></i></Card.Title>
                        <a href="/partenaires" className="stretched-link"></a>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};
