import React, { Component } from 'react';
import {
    Card,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Nav,
    Row,
    Toast
} from 'react-bootstrap';

class Envoyer extends Component {


    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        const { values: { disable, type, civilite, nom, prenom, piece, contact, email, departement,
            commune, arrondissement, quartier, rue, lot, typepoint, message, residence, rccm,
            contactofstructure, responsable, emailstructure, siege, entreprise } } = this.props;

            console.log(this.props.values)

        return (
            <div>

                <h1>Confirmer vos informations</h1>
                <p>Cliquez sur confirmer si toutes vos informations sont correctes</p>
                <Row>

                    {this.props.values.type == 'Physique' ?

                        <Col md={4} sm={10} xs={10}>
                            <ListGroup>
                                <ListGroup.Item>Type de personne: {type}</ListGroup.Item>
                                <ListGroup.Item>Civilité: {civilite}</ListGroup.Item>
                                <ListGroup.Item>Nom: {nom}</ListGroup.Item>
                                <ListGroup.Item>Prénom: {prenom}</ListGroup.Item>
                                <ListGroup.Item>N° Pièce d'identité: {piece}</ListGroup.Item>
                                <ListGroup.Item>Contact: {contact}</ListGroup.Item>
                                <ListGroup.Item>Email: {email}</ListGroup.Item>
                                <ListGroup.Item>Résidence: {residence}</ListGroup.Item>
                            </ListGroup>
                        </Col>

                        : ''
                    }

                    {this.props.values.type == 'Morale' ?

                        <Col md={4} sm={10} xs={10}>
                            <ListGroup>
                                <ListGroup.Item>Type de personne: {type}</ListGroup.Item>
                                <ListGroup.Item>Entreprise: {entreprise}</ListGroup.Item>
                                <ListGroup.Item>Numéro d'entreprise: {rccm}</ListGroup.Item>
                                <ListGroup.Item>Responsable: {responsable}</ListGroup.Item>
                                <ListGroup.Item>Contact de la structure: {contactofstructure}</ListGroup.Item>
                                <ListGroup.Item>Siège: {siege}</ListGroup.Item>
                            </ListGroup>
                        </Col>

                        : ''

                    }

                    <Col md={4} sm={10} xs={10}>
                        <ListGroup>
                            <ListGroup.Item>Département: {departement}</ListGroup.Item>
                            <ListGroup.Item>Commune: {commune}</ListGroup.Item>
                            <ListGroup.Item>Arrondissement: {arrondissement}</ListGroup.Item>
                            <ListGroup.Item>Quartier: {quartier}</ListGroup.Item>
                            <ListGroup.Item>Rue: {rue}</ListGroup.Item>
                            <ListGroup.Item>Lot: {lot}</ListGroup.Item>
                            <ListGroup.Item>Type de point d'eau: {typepoint}</ListGroup.Item>
                            <ListGroup.Item>Autres informations: {message}</ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>

                <p className="mt-3">
                    <button onClick={this.back} className="btn btn-primary btn-lg"  type="button"><i className="fa fa-angle-left" aria-hidden="true"></i> Retour</button>
                    {this.props.values.disable == 'disabled' ? <button disabled className="btn btn-outline-primary btn-lg ml-3"  type="submit" >En cours {this.props.values.button}</button> :
                        <button className="btn btn-outline-primary btn-lg ml-3"  type="submit" >Confirmer {this.props.values.button}</button>
                    }
                </p>
            </div>
        )
    }
}

export default Envoyer;
