import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';

const groupBy = require('lodash.groupby');

export default function AlphabeticalListing({items, itemSelected, labelField = 'name' }) {

  const [groupedItems, setGroupedItems] = useState([])

  useEffect(() => {
    if (!items) {
      setGroupedItems([])
      return;
    }
    const groups = groupBy(items, (item) => item[labelField].charAt(0).toUpperCase())
    setGroupedItems(groups);
  }, [items])

  return (
    <div className="alphabetical-listing">
      {Object.keys(groupedItems).length > 0 && Object.keys(groupedItems).map((groupKey) => (
        <div key={groupKey} className="letterGroup">
          <h3>{groupKey}</h3>
          <p>{groupedItems[groupKey].map((item) => (
            <Button variant="link" className="item my-0 px-0 text-decoration-none font-weight-bold" key={item.id}
              onClick={() => itemSelected(item)}>{item[labelField]}</Button>
          ))}
          </p>
          <hr/>
        </div>
      ))}

      {Object.keys(groupedItems).length == 0 &&
      <div className="mt-5">
        <h6>Aucun élément disponible</h6>
      </div>
      }
    </div>
  );
}
