import { get, getEntity } from './api';

const BASE_URL = '/pages';

const defaultApi = BASE_URL + '?include=thematique';

export default {
  getList(number) {
    return get(BASE_URL, {
      params: {
        per_page: number,
        'filter[accueil][op]': '=',
        'filter[accueil][value]': true,
      }
    });
  }
};
