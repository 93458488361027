import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

import React from "react";

import {
  Card,
  Col,
  Row
} from "react-bootstrap";
import { Link } from "react-router-dom";

export function HomeStaticCards() {

  return (
    <div className="home-static-cards">
        <Row className="no-gutters">
          <Col md={6}>
            <Card className="process">
              <Card.Body>
                <img src="./svg/process.svg" alt="" />
                <h4>Les procédures</h4>
                <p>
                  Faites vos demandes d'approvisionnement en
                  eau directement en ligne.
                </p>
                <Link className="btn btn-outline-primary btn-lg" to="/procedure">En savoir plus</Link>
                <p>
                  <a className="btn btn-outline-primary btn-lg" href="https://www.soneb.bj/soneb15/pme-pmi-raccordement-gratuit" target="_blank">
                    Demande de raccordement gratuit pour les PME et PMI
                  </a>
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="download">
              <Card.Body>
                <img src="./svg/download.svg" alt="" />
                <h4>Bibliothèques</h4>
                <p>
                  Toutes les données téléchargeables et réutilisables de Eau-Bénin à votre disposition.
                </p>
                <Link className="btn btn-outline-primary btn-lg" to="/rapports">En savoir plus</Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
    </div>
  );
};
