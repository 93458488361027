import React from "react";
import { SearchBox } from '../SearchBox';
import { Row, Col  } from "react-bootstrap";

export function HomeBannerSingle() {
  return (
    <div className="banner single">
        <div className="cta">
          <Row className="justify-content-center no-gutters">
            <Col md={6}>
              <h2 className="mt-3 text-center ">Toutes les données sur l'eau</h2>

              <div className="mt-4 mb-3">
                <SearchBox />
              </div>
            </Col>
          </Row>
        </div>
    </div>
  );
};
