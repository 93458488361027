import React, { Component } from "react";
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  Row,
  Form
} from 'react-bootstrap';


export default class AutresInformations extends Component {

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  render() {

    const { values } = this.props;

    return (
      <div>
        <Row >
          <Col md={6}>
            <Form.Group >
              <Form.Label style={{ fontSize: '25px', fontWeight: 'bold' }}>Autres informations</Form.Label>
              <Form.Control as="textarea" rows="10" defaultValue={values.message} name="message" onChange={this.props.handleChange('message')} />
            </Form.Group>
          </Col>
        </Row>

        <p>
          <button type="button" className="btn btn-primary btn-lg" onClick={this.back} ><i className="fa fa-angle-left" aria-hidden="true"></i> Retour</button>
          <button type="button" className="btn btn-outline-primary btn-lg ml-3" onClick={this.continue} > Suivant <i className="fa fa-angle-right" aria-hidden="true"></i></button>
        </p>
      </div>
    );
  }
};
