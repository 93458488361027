import "../styles/Banner/RapportInfoBanner.css";

import React from "react";
import BackToTop from '../components/BackToTop';

import classnames from "classnames";
import {
  Card,
  Container
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Line } from "react-chartjs-2";
import Moment from "react-moment";

import { RapportInfoBanner } from "../components/Banners/RapportInfoBanner";
import { RapportSimilaireBlock } from "../components/RapportSimilaireBlock";
import {
  getEntity,
  post
} from "../services/api";
import ChartService from "../services/ChartService";

export class Page extends React.Component{
  state = {
    page: {},
    loading: true,
    analyses: {}
  };

  constructor(props) {
    super(props);

    this.loadPage();
  }

  loadPage() {
    const slug = this.props.match.params.slug;
    const id = slug.split('-')[0];
    const url = 'pages/' + id + '?include=thematique.indicateurs';

    getEntity(url).then((responseData) => {
      const page = responseData.data.data;
      this.setState({ page });

      this.loadAnalyses(page.thematique.indicateurs)
    }).finally(() => this.setState({ loading: false }));
  }

  loadAnalyses(indicateurs) {
    indicateurs.map(indicateur => {
      if(!indicateur.important) {
        return;
      }

      post('analyse', {
        data: {
          indicateur_id: indicateur.id
        }
      }).then(res => {
        let analyses = this.state.analyses;
        analyses[indicateur.id] = ChartService.toBarData(res.data.data, indicateur.nom)
        this.setState({ analyses: { ...analyses } })
      });
    })
  }

  render() {
    return (
      <div className="Background pb-4 statique-page container-fluid">
        <RapportInfoBanner title={this.state.page.titre} />

        <Container className="text-form display-flex-center page-body">
          <Card className="shadow p-3 mb-5 bg-white rounded">

            <Card.Body className={classnames({'d-flex justify-content-center align-items-center': this.state.loading})}>
              <i className={classnames('fas fa-3x fa-circle-notch', { 'fa-spin': this.state.loading, 'd-none': !this.state.loading })}></i>
              <div className={classnames({'d-none': this.state.loading})}>
                <p className="mt-3 text-indent-left">Publié le <Moment format="DD/MM/YYYY">{this.state.page.updated_at}</Moment></p>

                <div dangerouslySetInnerHTML={{ __html: this.state.page.contenu }}></div>

                <Row className="justify-content-center">
                    {Object.keys(this.state.analyses).map(analyseKey => (
                      <Col key={analyseKey} md={6}>
                        <Line data={this.state.analyses[analyseKey]}/>
                      </Col>
                    ))}
                </Row>

                <RapportSimilaireBlock indicateurs={ this.state.page.thematique?.indicateurs }/>
              </div>
            </Card.Body>
          </Card>
        </Container>
        <BackToTop/>
      </div>
    );
  }
}
