import React from 'react';
import '../styles/Banner/RapportInfoBanner.css';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
  WhatsappIcon,
} from "react-share";

export function SocialShare({url, title}) {
  url = url || window.location.href;
  title = title || 'Eau Bénin';

  return <div>
    <p class="font-weight-bold">Partagez sur</p>
    <FacebookShareButton url={url} title={title}><FacebookIcon size={32} round /></FacebookShareButton>&nbsp;
    <TwitterShareButton url={url} title={title}><TwitterIcon size={32} round /></TwitterShareButton>&nbsp;
    <LinkedinShareButton url={url} title={title}><LinkedinIcon size={32} round /></LinkedinShareButton>&nbsp;
    <EmailShareButton url={url} title={title}><EmailIcon size={32} round /></EmailShareButton>&nbsp;
    <WhatsappShareButton url={url} title={title}><WhatsappIcon size={32} round /></WhatsappShareButton>
  </div>
}
