import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import classnames from 'classnames';
import { HomeBannerSingle } from '../components/home/BannerSingle';
import { Col, Nav, Row,ListGroup } from 'react-bootstrap';
import IndicateursCles from '../components/indicateurs/IndicateursCles';
import TousLesIndicateurs from '../components/indicateurs/TousLesIndicateurs';
import { SearchBoxLight } from '../components/SearchBoxLight';
import ThematiqueService from '../services/ThematiqueService';
import BackToTop from '../components/BackToTop';
import ReactGA from 'react-ga';
import { NavLink } from "react-router-dom";

import * as queryString from 'query-string';

export class Indicateurs extends React.Component {
  state = {
    selectedTab: 'indicateurs-cles',
    query: '',
    selectedThematiqueIds: [],
    thematiques: [],
    loading: true
  };

  constructor(props) {
    super(props);

    this.history = props.history;

    this.handleTabSelect = this.handleTabSelect.bind(this);
    this.handleQueryChanged = this.handleQueryChanged.bind(this);
    this.setSelectedThematique = this.setSelectedThematique.bind(this);
    this.selectIndicateur = this.selectIndicateur.bind(this);
  }

  async componentDidMount() {
    await this.loadThematiques();
    this.setThematiqueFromHistory();
  }

  handleTabSelect(eventKey) {
    this.setState({ selectedTab: eventKey });
  }

  handleQueryChanged(val) {
    this.setState({ query: val ? val.toLowerCase() : '' });
  }

  setSelectedThematique(id) {
    let selectedThematiqueIds = [];
    if (id) {
      selectedThematiqueIds = [id];
    }

    this.setState({ selectedThematiqueIds, selectedTab: 'tous-les-indicateurs' })
  }

  selectIndicateur(indicateur) {
    let parsed = queryString.parse(window.location.search);
    delete parsed.thematique;

    if (!parsed.area_type || !parsed.area_id) {
      this.history.push('/geographies?' + queryString.stringify({
        ...parsed,
        indicateur: indicateur.id,
      }));

      ReactGA.event({
      category: 'Indicateur',
      action: indicateur.nom
      });

    } else {
      this.history.push('/analyse?' + queryString.stringify({
        ...parsed,
        indicateur: indicateur.id,
      }));

      ReactGA.event({
      category: 'Indicateur',
      action: indicateur.nom
      });

    }
  }

  async loadThematiques() {
    const response = await ThematiqueService.getList(true, 25, {}, {}, {nom: 'ASC'});

    this.setState({ thematiques: response.data.data, loading: false });
  }

  setThematiqueFromHistory() {
    const query = queryString.parse(window.location.search, {arrayFormat: 'brackets'});
    const thematique = query.thematique ? parseInt(query.thematique, 10) : null;

    if(Number.isInteger(thematique)) {
      this.setSelectedThematique(thematique)
    }
  }

  render() {
    return (
      <div id="pg-indicateurs">
        <HomeBannerSingle/>

        <Container className="mt-5 content mb-5">
          <h2 className="my-3">Les indicateurs</h2>

          <Row>
          <Col md={4}>
              <div className={classnames('shadow rounded', {'d-flex justify-content-center align-items-center': this.state.loading})}>
                <i className={classnames('fas fa-2x fa-circle-notch', { 'fa-spin': this.state.loading, 'd-none': !this.state.loading })}></i>
                <ListGroup className={classnames({ 'd-none': this.state.loading })}>
                  <ListGroup.Item action className="font-weight-bold d-flex justify-content-between align-items-center"
                    onClick={() => this.setSelectedThematique(null)}>
                    Toutes les thématiques
                    <i className='fas fa-chevron-right'></i>
                  </ListGroup.Item>
                  {this.state.thematiques.map(t => {
                    return <ListGroup.Item action className="font-weight-bold d-flex justify-content-between align-items-center"
                      onClick={() => this.setSelectedThematique(t.id)} key={t.id}>{ t.nom }
                      <i className='fas fa-chevron-right'></i>
                    </ListGroup.Item>
                  })}
                </ListGroup>
              </div>
            </Col>
            <Col md={8}>
              <div className="content-body shadow rounded">
                <Row>
                  <Col sm={8}>
                    <Nav activeKey={this.state.selectedTab} onSelect={this.handleTabSelect} className="custom-navs nav-indicateurs-types">
                      <Nav.Item>
                        <Nav.Link eventKey="indicateurs-cles">Indicateurs Clés</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="tous-les-indicateurs">Tous les indicateurs</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col md={4}>
                    <SearchBoxLight handleQueryChanged={this.handleQueryChanged}></SearchBoxLight>
                  </Col>
                </Row>

                <hr/>

                <div className="tabs-content">
                  <div className={[this.state.selectedTab === 'indicateurs-cles' ? 'd-block' : 'd-none']}>
                    <IndicateursCles query={this.state.query} handleIndicateurSelected={this.selectIndicateur}></IndicateursCles>
                  </div>
                  <div className={this.state.selectedTab === 'tous-les-indicateurs' ? 'd-block' : 'd-none'}>
                    <TousLesIndicateurs searchQuery={this.state.query} thematiqueIds={this.state.selectedThematiqueIds} handleIndicateurSelected={this.selectIndicateur}></TousLesIndicateurs>
                  </div>
                   <NavLink to="/rapports"><span className="btn btn-primary">En savoir plus</span></NavLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <BackToTop/>
      </div>
    );
  }
}
