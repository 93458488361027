import axios from 'axios';
import paramSerializer from 'jquery-param';

// import AsyncStorage from '@react-native-community/async-storage'

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

axios.defaults.baseURL = API_BASE_URL;

/**
 *
 * @param {string} entity_url
 * @param {Array} filters
 * @param {number} limit
 */
export const getEntity = (url, params = {}) => {
  params = paramSerializer(params);
  if (params) {
    url = url + "?" + params;
  }
  return axios.get(url, {
    headers: {'Content-Type': 'application/json'}})
}

/**
 *
 * @param {string} url
 */
export const getOAuthToken = (url) => {
  return axios.get(url, {headers: {'Content-Type': 'application/json'}})
}


/**
 *
 * @param {string} entity_url - Url for API
 * @param {object} data - Data
 * @param {object} headers
 */
export const postEntity = (entity_url, data, headers) => {

  return axios.post(entity_url, data, headers)
}

/**
 *
 * @param {string} entity_url
 * @param {number} id
 * @param {object} data
 * @param {object} headers
 */
export const putEntity = (entity_url, id, data, headers) => {
  return axios.put(entity_url + '/' + id, data, headers)
}

export const putEntityByUrl = (entity_url, data, headers) => {
  return axios.put(entity_url, data, headers)
}

/**
 *
 * @param {string} entity_url
 * @param {number} id
 */
export const removeEntity = (entity_url, id) => {
  return axios.delete(entity_url + '/' + id)
}


export const get = (url, options) => {
  return axios({
    method: 'get',
    url,
    ...options,
  })
}


export const post = (url, options) => {
  return axios({
    method: 'post',
    url,
    ...options,
  })
}


export const put = (url, options) => {
  return axios({
    method: 'put',
    url,
    ...options,
  })
}


export const deletion = (url, options) => {
  return axios({
    method: 'delete',
    url,
    ...options,
  })
}
