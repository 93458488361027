import React, { useEffect, useState } from "react";
import { Link, dangerouslySetInnerHTML } from "react-router-dom";
import { getEntity } from '../../services/api';
import bgImage from '../../static/images/header.jpg';

export function HomeBanner() {

  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState([]);
  const [textData, setTextData] = useState([]);
  let storeData = [];
  let storeTextData = [];

  useEffect(() => {

    const query = { per_page: 1, filter: { active: true }, sort: { created_at: 'desc' } }

    getEntity('banner', query).then(res => {
      if (res.data.data.length) {
        storeData.push(res.data.data[0])
        setData(storeData);
      }
      setDataLoaded(true);
    })

    getEntity('text_accueils', query).then(res => {
      if (res.data.data.length) {
        storeTextData.push(res.data.data[0])
        setTextData(storeTextData);
      }
    })

  }, []);

  function removeHTML(str) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }

  function createMarkup(str) {
    return {
      __html: str
    };
  };

  return (
    dataLoaded && <div className="banner home" style={{ backgroundImage: data.length ? `url(${data[0]['image']['url']})` : `url(${bgImage})` }}>
      <div className="cta">
        {textData && textData.map(titreText => (
          <h1 dangerouslySetInnerHTML={createMarkup(titreText.titre)}></h1>
        ))}
        {textData && textData.map(contentText => (
          <div className="cta-description">
            <p dangerouslySetInnerHTML={createMarkup(contentText.contenu)} />
          </div>
        ))}
        <p>
          <Link variant="primary" style={{ textDecoration: 'none' }} className="primary cta-btn btn-lg" size="lg" to="/indicateurs">
            Consulter les données disponibles
          </Link>
        </p>
      </div>
    </div>
  );
};
