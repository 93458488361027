import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import classnames from 'classnames';

import LetterSelector from './LetterSelector';
import AlphabeticalListing from './AlphabeticalListing';

const groupBy = require('lodash.groupby');

export default function ListingWithFilter({data, handleItemSelected, query, labelField = 'name', loading, children }) {
  const [selectedLetter, setSelectedLetter] = useState('')
  const [sanitizedQuery, setSanitizedQuery] = useState('')
  const [items, setItems] = useState([])
  const [groupItems, setGroupItems] = useState([])

  useEffect(() => {
    const sortedItems = data ?
      data.sort((a, b) => a[labelField].localeCompare(b[labelField])) :
      [];

    setItems(sortedItems)
  }, [data]);

  useEffect(() => {
    setSanitizedQuery(query ? query.toLowerCase() : '')
  }, [query]);

  useEffect(() => {
    filterItems()
  }, [items, sanitizedQuery, selectedLetter]);

  const filterItems = () => {
    const firstLetterItems = selectedLetter ?
      items.filter(item => item[labelField].charAt(0) == selectedLetter) :
      items;

    const queryItems = sanitizedQuery ?
      firstLetterItems.filter(item => item[labelField].toLowerCase().includes(sanitizedQuery)) :
      firstLetterItems;

    const groups = groupBy(queryItems, (item) => item[labelField].charAt(0).toUpperCase())

    setGroupItems(groups);
  }

  const handleLetterSelected = (letter) => {
    setSelectedLetter(letter)
  }

  return (
    <div>
      <LetterSelector letterSelected={handleLetterSelected}></LetterSelector>
      {children}

      <div className={classnames('alphabetical-listing', {'d-flex justify-content-center align-items-center': loading})}>
        <i className={classnames('fas fa-3x fa-circle-notch', { 'fa-spin': loading, 'd-none': !loading })}></i>
        {Object.keys(groupItems).length > 0 && Object.keys(groupItems).map((groupKey) => (
          <div key={groupKey} className="letterGroup">
            <h3>{groupKey}</h3>
            <p>{groupItems[groupKey].map((item) => (
              <Button variant="link" className="item my-0 px-0 text-decoration-none font-weight-bold" key={item.id}
                onClick={() => handleItemSelected(item)}>{item[labelField]}</Button>
            ))}
            </p>
            <hr/>
          </div>
        ))}

        {!loading && Object.keys(groupItems).length == 0 &&
        <div className="mt-5">
          <h6>Aucun élément disponible</h6>
        </div>
        }
      </div>
    </div>
  );
}
