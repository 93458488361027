import React from 'react';
import '../styles/Banner/RapportInfoBanner.css';
import {Row, Col} from 'react-bootstrap';

export function RapportSimilaireBlock({indicateurs}) {

  return indicateurs && indicateurs.length > 0 ? (
    <Row>
      <Col>
        <hr className="mt-5"/>
        <h4>Plus d'indicateurs sur la thématique</h4>
        <ul className="nav page-more">
          {indicateurs.map(i => {
            return (
              <li className="nav-item" key={i.id}>
                <a className="nav-link active" href={`/geographies?indicateur=${i.id}`}>{i.nom}</a>
              </li>
            )
          })}
        </ul>
      </Col>
    </Row>
  ) : null;
}
