import React, { Component } from "react";
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  Row,
  Form,
  Alert
} from 'react-bootstrap';
import './style.css';


export default class InformationsGenerale extends Component {

  state = {
    errors : {}
  };



  continue = e => {

    e.preventDefault();
    // const mailValue = document.getElementById("getMail").value;

    const { values } = this.props;

    //this.props.nextStep();
   

    const errors = this.state.errors;

    if(values.type === 'Morale'){
        this.checkMoraleInfo();
    }else{
        this.checkPhysiqueInfo();
    }  
  }

  checkMoraleInfo = () => {
    const { values } = this.props;

    const errors = this.state.errors;

    if(!values.responsable || !values.entreprise || !values.contactofstructure || !values.emailstructure){
      this.setState({errors : "Required"});
    }else{
      if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(values.emailstructure)){ 
      this.setState({errors : ""});
       this.props.nextStep(); 
      }else {
        this.setState({errors : "Email"});
    
      }
    }
  
  }

  checkPhysiqueInfo = () => {
    const { values } = this.props;

    // const mailCheck = document.getElementById("checkMail").value; 

    const errors = this.state.errors;

    if(!values.nom || !values.civilite || !values.prenom || !values.contact || !values.email){
      this.setState({errors : "Required"});
    }else{
      if (/^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]{2,}[.][a-z]{2,3}$/.test(values.email)){
      this.setState({errors : ""});
       this.props.nextStep(); 
      }else {
        this.setState({errors : "Email"});
      }
    }
  }


  render() {

    const { values } = this.props;

    const {errors} = this.state;

    return (

      <div>

       { errors == 'Required' ?
                <Alert variant="warning">
                Veuillez renseignez le ou les champs portant l'étoile(*)
              </Alert>
              : errors == 'Email' ?
              <Alert variant="warning">
                Veuillez renseignez une adresse email valide
              </Alert>
              : ''
            }

        <Row>

          <Col md={3} sm={10} xs={8}>
            <Form.Group >
              <Form.Label>Type de personne <abbr title="Ce champs est requis">*</abbr></Form.Label>
              <Form.Control as="select" size="lg" value={values.type}  defaultValue={values.type} name="type" onChange={this.props.handleChange('type')} >
                <option value="Morale">Morale</option>
                <option value="Physique">Physique</option>
              </Form.Control>
            </Form.Group>
          </Col>

          {values.type == 'Physique' ?

            <Col md={3} sm={10} xs={8}>
              <Form.Group >
                <Form.Label>Civilité <abbr title="Ce champs est requis">*</abbr></Form.Label>
                <Form.Control as="select" size="lg" defaultValue={values.civilite} name="civilite" onChange={this.props.handleChange('civilite')} >
                  <option hidden disabled selected="selected" value=""  >Choisir une civilité</option>
                  {/* <option value="" selected disabled hidden>Choose here</option> */}
                  <option value="Monsieur">Monsieur</option>
                  <option value="Madame">Madame</option>
                  {/* <option disabled selected >Choisir une civilité</option> */}
                </Form.Control>
              </Form.Group>
            </Col>

            : ''

          }

        </Row>

        {values.type == 'Physique' ?

          <Row>

            <Col md={3} sm={10} xs={8}>
              <Form.Group >
                <Form.Label>Nom <abbr title="Ce champs est requis">*</abbr></Form.Label>
                <Form.Control  defaultValue={values.nom} size="lg" type="text" placeholder="GUELELE" name="nom" onChange={this.props.handleChange('nom')} />
              </Form.Group>
              
            
            </Col>

            <Col md={3} sm={10} xs={8}>
              <Form.Group >
                <Form.Label>Prénom<abbr title="Ce champs est requis">*</abbr></Form.Label>
                <Form.Control defaultValue={values.prenom} size="lg" type="text" placeholder="Isaie" name="prenom" onChange={this.props.handleChange('prenom')} />
              </Form.Group>
            
            </Col>

            <Col md={3} sm={10} xs={8}>
              <Form.Group >
                <Form.Label>N° Pièce d'identité</Form.Label>
                <Form.Control size="lg" type="text" defaultValue={values.piece} placeholder="2016BEN5555" name="piece" onChange={this.props.handleChange('piece')} />
              </Form.Group>
            
            </Col>



            <Col md={3} sm={10} xs={8}>
              <Form.Group >
                <Form.Label>Contact <abbr title="Ce champs est requis">*</abbr></Form.Label>
                <Form.Control size="lg" type="tel" defaultValue={values.contact} placeholder="+229 61342454" name="contact" onChange={this.props.handleChange('contact')} />
              </Form.Group>
              
            </Col>

            <Col md={3} sm={10} xs={8}>
              <Form.Group >
                <Form.Label>Résidence</Form.Label>
                <Form.Control size="lg" type="text" defaultValue={values.residence} placeholder="lot/N°Rue/Arrondissement" name="residence" onChange={this.props.handleChange('residence')} />
              </Form.Group>
             
            </Col>

            <Col md={3} sm={10} xs={8}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Email <abbr title="Ce champs est requis">*</abbr></Form.Label>
                <Form.Control size="lg" id="checkMail" type="email" defaultValue={values.email} placeholder="isaie@gmail.com" name="email" onChange={this.props.handleChange('email')} />
              </Form.Group>
              
            </Col>
          </Row>

          : ''

        }

        {values.type == 'Morale' ?
          <>
            <Row>
              <Col md={3} sm={10} xs={8}>
                <Form.Group >
                  <Form.Label>Nom de la structure <abbr title="Ce champs est requis">*</abbr></Form.Label>
                  <Form.Control size="lg" type="text" defaultValue={values.entreprise} placeholder="Mairie de Parakou" name="entreprise" onChange={this.props.handleChange('entreprise')} />
                </Form.Group>
                
              </Col>

              <Col md={3} sm={10} xs={8}>
                <Form.Group >
                  <Form.Label>Numéro d'enrégistrement <abbr title="Ce champs est requis">*</abbr></Form.Label>
                  <Form.Control size="lg" type="text" defaultValue={values.rccm} placeholder="rccm/numéro" name="rccm" onChange={this.props.handleChange('rccm')} />
                </Form.Group>
               
              </Col>

              <Col md={3} sm={10} xs={8}>
                <Form.Group >
                  <Form.Label>Personne responsable <abbr title="Ce champs est requis">*</abbr></Form.Label>
                  <Form.Control size="lg" type="email" defaultValue={values.responsable} placeholder="DEGAKON" name="responsable" onChange={this.props.handleChange('responsable')} />
                </Form.Group>
               
              </Col>
            </Row>
            <Row>
              <Col md={3} sm={10} xs={8}>
                <Form.Group >
                  <Form.Label>Contact de la structure <abbr title="Ce champs est requis">*</abbr></Form.Label>
                  <Form.Control size="lg" type="email" defaultValue={values.contactofstructure} placeholder="+229 21457896" name="contactofstructure" onChange={this.props.handleChange('contactofstructure')} />
                </Form.Group>
               
              </Col>

              <Col md={3} sm={10} xs={8}>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Mail de la structure <abbr title="Ce champs est requis">*</abbr></Form.Label>
                  <Form.Control size="lg" type="email" defaultValue={values.emailstructure} placeholder="mairieparakou@gmail.com" name="emailstructure" onChange={this.props.handleChange('emailstructure')} />
                </Form.Group>
                
              </Col>

              <Col md={3} sm={10} xs={8}>
                <Form.Group >
                  <Form.Label>Siège de la structure</Form.Label>
                  <Form.Control size="lg" type="email" defaultValue={values.siege} placeholder="siege" name="lot/rue/arrondissement" onChange={this.props.handleChange('siege')} />
                </Form.Group>
              </Col>
            </Row>
          </>

          : ''
        }

        <p>
          <small className="mb-3">Veuillez consulter la rubrique <a href="https://ressources-eau.gouv.bj/pages/49-mentions-legales">"Mentions légales"</a>  pour toute information relative aux données à caractère personnel.</small>
         <br />
          <button className="btn btn-primary btn-lg mt-3" onClick={this.continue} type="button" >
            Suivant <i className="fa fa-angle-right" aria-hidden="true"></i>
          </button>
        </p>
      </div>

    );
  }

};
