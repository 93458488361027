import React, { useEffect, useState } from 'react';
import CommuneService from '../../services/CommuneService';
import ListingWithFilter from '../ListingWithFilter';

export default function CommuneSelectorTab({handleCommuneSelected, query}) {
  const [communes, setCommunes] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await loadCommunes();
    })()
  }, []);

  const loadCommunes = async () => {
    const res = await CommuneService.getAll();
    setCommunes(res.data.data);
    setLoading(false);
  }

  return (
    <ListingWithFilter data={communes} handleItemSelected={handleCommuneSelected} query={query} loading={loading}></ListingWithFilter>
  );
}
