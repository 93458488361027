import React from "react";
import { Row, Col, Container } from "react-bootstrap";

export function DownloadApp() {

  return (
    <div className="home-download-apps">
      <Container>
        <Row className="no-gutters">
          <Col md={12}>
            <h2 className="title-bordered mb-5">Téléchargez l'application</h2>
            <Row>
              <Col md={12}>
                <p>
                  <a href="https://play.google.com/store/apps/details?id=com.siebeninmobile">
                    <img src="./assets/image/play-store.png" />
                  </a>
                  <a href="https://apps.apple.com/app/id1525102581">
                    <img src="./assets/image/apple-store.png" />
                  </a>
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
