import React, {useState} from "react";
import {
  Card,
  Form,
  Button,
  Alert
} from 'react-bootstrap';
import BackToTop from '../components/BackToTop';
import { postEntity } from "../services/api";

export function Contact() {
  const style = {
    backgroundColor: '#DE9200',
    borderRadius: '4px',
    padding: '5px'
  };

  const style1 = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '21px',
    lineHeight: '25px'
  };

  const style2 = {
    fontFamily: 'Montserrat',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '21px',
    lineHeight: '25px',
    color: '#777777'
  }

  const [validated, setValidated] = useState(false);
  const [data, setData] = useState([]);
  const [alert, setAlert] = useState(null);
  const [disable, setDisable] = useState('');


  // const Reset = () => {
  //   document.getElementById("contact-form").reset();
  // }

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    if(form.checkValidity() === true){
      setDisable("disabled");
      postEntity('/contacts', data).then(() => {
        setAlert({ success: true, message: 'Votre message a été prise en compte' })
      }, () => {
        setAlert({ success: false, message: 'Erreur l\'ors de l\'envoie' });
      }).finally(() => {
        setTimeout(() => {
          setAlert(null);
          setDisable("")
          window.location.reload();
        }, 5000);
      });
    }else{
      setAlert({ success: false, message: 'Veuillez renseigner tout les champs' });
    }

    
  };

  const setName = (e) => {
      setData({...data,nom: e.target.value});
  }

  const setEmail = (e) => {
    setData({...data,email: e.target.value});
}


const setMessage = (e) => {
  setData({...data,message: e.target.value});
}


  return (
    <div>
      <div className="banner home">
      
        <Card className="offset-7 col-md-4 pt-3 mt-3" style={{
          borderRadius: '4px', boxShadow: '0px 4px 60px rgba(0, 0, 0, 0.25)', position: 'relative',
          top: '230px', left: '20px'
        }}>
          <div className="text-center">
            <h3 className="mb-5" style={{ color: '#0A3764' }}><b>Demandes et Suggestions</b></h3>
            <hr className="" style={{ width: '100px', marginTop: '-35px' }} />
          </div>
          <Card.Body>
          {
            alert && <Alert variant={alert.success ? 'success' : 'danger'}>
              { alert.message }
             </Alert>
          }
            <blockquote className="blockquote mb-0">
              <Form id="contact-form" className="col-md-12" noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group controlId="validationCustom01">
                  <Form.Label style={style2}>Nom et prénom</Form.Label>
                  <Form.Control name="nom" onChange={setName} required type="text" />
                  <Form.Control.Feedback>Correct!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                        Veuillez renseigner ce champ
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label style={style2}>Email</Form.Label>
                  <Form.Control name="email" onChange={setEmail} required type="email" />
                  <Form.Control.Feedback>Correct!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                        Contenu invalid
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                  </Form.Text>
                </Form.Group>

                <Form.Group controlId="formBasicEmail">
                  <Form.Label style={style2}>Message</Form.Label>
                  <Form.Control name="message" onChange={setMessage} required as="textarea" rows="4" />
                  <Form.Control.Feedback>Correct!</Form.Control.Feedback>
                  <Form.Control.Feedback type="invalid">
                        Veuillez renseigner ce champ
                  </Form.Control.Feedback>
                  <Form.Text className="text-muted">
                  </Form.Text>
                </Form.Group>
                <small className="mb-3" style={{fontSize:'13px'}}>Veuillez consulter la rubrique <a href="https://ressources-eau.gouv.bj/pages/49-mentions-legales">"Mentions légales"</a>  pour toute information relative aux données à caractère personnel.</small> <br />
                { disable == 'disabled' ? <Button disabled variant=" btn-block" style={{ backgroundColor: '#0A3764', marginTop:'20px' }} type="submit">
                  <span style={{ fontSize: '23px', fontWeight: 'bold', color: 'white' }}>En cours ...</span>
                </Button> :
                <Button variant=" btn-block" style={{ backgroundColor: '#0A3764', marginTop:'20px' }} type="submit">
                <span style={{ fontSize: '23px', fontWeight: 'bold', color: 'white' }}>Envoyer</span>
                </Button>
                }
              </Form>
            </blockquote>
          </Card.Body>
        </Card>
      </div>

      <div className="offset-2 col-md-4 mt-5">
        <h3 className="ml-4 mb-5" style={{ color: '#0A3764' }}><b>Contactez-nous</b></h3>
        <hr className="" style={{ width: '180px', marginTop: '-35px', marginLeft: '50px' }} />
        <p style={style1}>Faites nous parvenir vos suggestions</p>
      </div>

      <div className="offset-2 col-md-4 mt-5">
        <p><span style={style}><svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 0V0.730469L9 5.13047L0 0.730469V0H18ZM9 6.96953L18 2.56953V11H0V2.56953L9 6.96953Z" fill="white" /></svg></span><span style={style1} className="ml-5">sgm.mem.2018@gmail.com</span>
        </p>

        <p><span style={style}><svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.21778 6.92444C4.49778 9.44 6.56 11.5022 9.07556 12.7822L11.0311 10.8267C11.28 10.5778 11.6267 10.5067 11.9378 10.6044C12.9333 10.9333 14 11.1111 15.1111 11.1111C15.602 11.1111 16 11.5091 16 12V15.1111C16 15.602 15.602 16 15.1111 16C6.76548 16 0 9.23452 0 0.888889C0 0.653141 0.0936505 0.427049 0.26035 0.26035C0.427049 0.0936505 0.653141 0 0.888889 0H4C4.49092 0 4.88889 0.397969 4.88889 0.888889C4.88889 2 5.06667 3.06667 5.39556 4.06222C5.49333 4.37333 5.42222 4.72 5.17333 4.96889L3.21778 6.92444Z" fill="white" /></svg></span><span style={style1} className="ml-5">+229 96430446</span>
        </p>

        <p>
          <span style={{ background: '#DE9200', borderRadius: '4px', padding: '5px 7px 5px 7px' }}><svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M6 8.55005C4.81654 8.55005 3.85715 7.54269 3.85715 6.30005C3.85715 5.70331 4.08291 5.13102 4.48478 4.70906C4.88664 4.2871 5.43168 4.05005 6 4.05005C7.18347 4.05005 8.14286 5.05741 8.14286 6.30005C8.14286 6.89679 7.9171 7.46908 7.51523 7.89104C7.11337 8.313 6.56833 8.55005 6 8.55005ZM6 0C2.68629 0 0 2.82061 0 6.3C0 11.025 6 18 6 18C6 18 12 11.025 12 6.3C12 2.82061 9.31371 0 6 0Z" fill="white" /></svg></span><span style={style1} className="ml-5">Cotonou/Cadjehoun(à coté de l'agence UBA)</span>
        </p>

      </div>

      <BackToTop/>

    </div>
  );
};
