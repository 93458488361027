import React, { Component } from "react";
import '../styles/approvisionnementEau.css';
import {
    Card,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Nav,
    Row,
    Form
} from 'react-bootstrap'
import { postEntity } from "../services/api";
import InformationsGenerale from '../components/approvisionnementPages/InformationsGenerale';
import ZoneDemande from '../components/approvisionnementPages/ZoneDemande';
import PointEau from '../components/approvisionnementPages/PointEau';
import AutresInformations from '../components/approvisionnementPages/AutresInformations';
import Envoyer from '../components/approvisionnementPages/Envoyer';
import BackToTop from '../components/BackToTop';
import SweetAlert from 'react-bootstrap-sweetalert';

export class ApprovisionnementEau extends Component {

    state = {
        disable: '',
        button: <i className="fa fa-check" aria-hidden="true"></i>,

        step: 1,

        // step 1
        type: 'Morale',
        civilite: '',
        nom: '',
        prenom: '',
        piece: '',
        contact: '',
        email: '',
        residence: '',
        entreprise: '',
        rccm: '',
        responsable: '',
        contactofstructure: '',
        emailstructure: '',
        siege: '',


        // step 2
        departement: '',
        commune: '',
        arrondissement: '',
        quartier: '',
        rue: '',
        lot: '',

        // step 3
        Typepoint: '',

        // step 4
        message: ''
    }

    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event, values) {
        event.preventDefault();

        this.setState({disable:'disabled'})
        this.setState({button: <i className="fa fa-spinner fa-pulse fa-1x fa-fw" aria-hidden="true"></i>})


        postEntity('approvisionnements', values)
            .then((response) => {
               
                this.setState({disable:''})
                this.setState({button: <i className="fa fa-check" aria-hidden="true"></i>})
                alert('Votre demande a été soumise. Vous serez recontactez très bientot');
                window.location.reload()
            }, () => {
 
                this.setState({disable:''})
                this.setState({button: <i className="fa fa-check" aria-hidden="true"></i>})
                alert('Erreur lors de la soumission des données');
                window.location.reload()
              })

    }



    nextStep = () => {
        const { step } = this.state;

        this.setState({ step: step + 1 });
    }

    prevStep = () => {
        const { step } = this.state;

        this.setState({ step: step - 1 });
    }

    handleChange = input => event => {
       
        this.setState({ [input]: event.target.value });

    }

    changeState = (key,value) => {
        let newState = this.state
        newState[key]=value
        this.setState(newState);
    }



    render() {

        const { step } = this.state;

        const { disable, button, type, civilite, nom, prenom, piece, contact, email, departement,
            commune, arrondissement, quartier, rue, lot, typepoint, message, residence, rccm,
            contactofstructure, responsable, emailstructure, siege, entreprise } = this.state;

        const values = {
            disable, button, type, civilite, nom, prenom, piece, contact, email, departement,
            commune, arrondissement, quartier, rue, lot, typepoint, message, residence, rccm,
            contactofstructure, responsable, emailstructure, siege, entreprise
        };

        return (
            <div>
                <Col className='text-center' style={{ color: 'white', backgroundColor: '#DE9200' }}>
                    <h1 style={{ paddingTop: '20px', paddingBottom: '20px' }}>Approvisionnement en eau</h1>
                </Col>

                <Nav defaultActiveKey="1" className="justify-content-center margin">
                    <Nav.Item>
                        <Nav.Link eventKey="1" className={`fontStyle ml-5 ${step === 1 ? "text-black" : "text-muted"}`}>1. Informations générale <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="2" className={`fontStyle  ml-5 ${step === 2 ? "text-black" : "text-muted"}`}>2. Zone de demande <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="3" className={`fontStyle  ml-5 ${step === 3 ? "text-black" : "text-muted"}`}>3. Point d'eau <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="4" className={`fontStyle  ml-5 ${step === 4 ? "text-black" : "text-muted"}`}>4. Autres informations <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                        <Nav.Link eventKey="5" className={`fontStyle  ml-5 ${step === 5 ? "text-black" : "text-muted"}`}>5 .Envoyer <i className="fa fa-angle-right" aria-hidden="true"></i></Nav.Link>
                    </Nav.Item>
                </Nav>

                <div style={{ backgroundColor: 'white' }} className="bottom">

                <Card className="cardPosition col-11">

                <Form onSubmit={(e) => this.handleSubmit(e, values)}>

                    {step === 1 ?

                        <InformationsGenerale
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />

                        : ''

                    }

                    {step === 2 ?

                        <ZoneDemande
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            changeState={this.changeState}
                            values={values}
                        />

                        : ''

                    }

                    {step === 3 ?

                        <PointEau
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            values={values}
                        />

                        : ''

                    }

                    {step === 4 ?

                        <AutresInformations
                            nextStep={this.nextStep}
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            values={values}
                        />

                        : ''

                    }

                    {step === 5 ?


                        <Envoyer
                            prevStep={this.prevStep}
                            handleChange={this.handleChange}
                            values={values}
                        />

                        : ''

                    }
                </Form>

            </Card>

                </div>

                <BackToTop/>

            </div>

        );

    }

};
