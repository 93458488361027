import React from 'react';
import {
	Card,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Nav,
	Row,
	Form,
	Accordion,
	Button
} from 'react-bootstrap';
import './style.css';

import Un from './guideImages/32.PNG';
import Deux from './guideImages/33.PNG';
import Trois from './guideImages/34.PNG';
import Quatre from './guideImages/35.PNG';
import Cinq from './guideImages/36.PNG';
import Six from './guideImages/37.PNG';
import Sept from './guideImages/38.PNG';
import Huit from './guideImages/39.PNG';
import Neuf from './guideImages/40.PNG';
import Dix from './guideImages/41.PNG';
import Onze from './guideImages/42.PNG';
import Douze from './guideImages/43.PNG';

import alert from './guideImages/alert.PNG';



export default function Section() {

	return (

			<div>
				<p>1. Pour afficher les données récentes ou les actualités,
				 <br/>cliquez sur l’un des liens se trouvant dans les deux blocs aux extrémités :</p>

				 <img src={Un} /><br/>

				<p>2. Pour en savoir plus sur les procédures de demande d’approvisionnement en eau, 
				<br/>allez sur la page d’accueil et scrollez deux fois puis cliquez sur le bouton ‘’en savoir plus’’</p>

				<img src={Deux} /><br/>

				<p>Vous accéderez ainsi au formulaire de soumission d’une demande d’approvisionnement. Ce formulaire est composé de cinq étapes :</p>

				<p>Etape1 : Après avoir choisi le type de personne vous compléter les autres champs du formulaire</p>

				<img src={Trois} /><br/>

				<p>Etape2 : Vous remplissez la zone de demande.</p>

				<img src={Quatre} /><br/>

				<p>Etape3 : Vous choisissez le point d’eau</p>

				<img src={Cinq} /><br/>

				<p>Etape4 : Ce champ est prévu pour les informations diverses liées à votre demande mais qui
				 ne figure pas dans les niveaux précédents du formulaire</p>

				 <img src={Six} /><br/>

				<p>Etape5 : Ici vous avez un aperçu des informations préalablement saisi.
				 Si elles sont conformes vous pouvez cliquer sur le bouton de confirmation pour valider</p>

				 <img src={Sept} /><br/>
				 <span>Notez que les champs portant des étoiles rouges sont des champs requis</span>
				 <span>Lorsque ces champs ne sont pas renseigné vous avez le message suivant:</span>

				 <img src={alert} /><br/>

				 <p>3.	Pour en savoir plus sur les ressources téléchargeable de Eau Bénin, allez sur la page d’accueil
				  <br/>et scrollez deux fois puis cliquez sur le bouton ‘’en savoir plus’’ :</p>

				  <img src={Huit} /><br/>

				  <p>4.	Lorsque vous cliquer vous ce bouton vous êtes redirigé sur la page d’affichage
				   des rapports sur l’eau groupés par thématique :</p>

				   <img src={Neuf} /><br/>

				   <span>Vous pouvez donc télécharger des données sur l’eau.</span>

				   <p>5. La section ‘’Plus de ressources’’ se trouvant sur la page d’accueil présente les différentes thématiques :</p>

				   <img src={Dix} /><br/>

				   <p>6. Pour avoir les informations liées à une thématique, il faut cliquer sur cette thématique.</p>

				   <img src={Onze} /><br/>

				   <p>7. Vous accéder ainsi donc à la page suivante spécifique à la thématique choisi :</p>

				   <img src={Douze} /><br/>

				   <p>8. Dans la section ‘’Nos partenaires’’, lorsque vous cliquez sur un partenaire, vous êtes automatiquement redirigé sur son site web.</p>

				   <p>9. Dans la section ‘’Télécharger l’application’’, vous avez les liens de redirection sur Play Store et App Store pour le téléchargement de la version mobile.</p>
				 
			</div>

			);
		}