import "react-bootstrap-typeahead/css/Typeahead-bs4.css";

import React from "react";

import classnames from "classnames";
import {
  Card,
  Col,
  Container,
  Row
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { ICONES_IMAGES } from "../../constants/icon";
import PageService from "../../services/PageService";

export class HomeMoreResources extends React.Component {
  state = {
    pages: [],
    loading: true
  }

  componentDidMount() {
    this.loadPages();
  }
  async loadPages() {
    const res = await PageService.getList(6);
    this.setState({ pages: res.data.data, loading: false });
  }

  findPageIcone(icone) {
    return ICONES_IMAGES['Bleu']['PNG'][icone] || '';
  }

  render() {
    return (
      <div className="home-more-resources">
        <Container>
          <Row className="no-gutters">
            <Col md={12}>
              <h2 className="title-bordered to-left">Services aux usagers</h2>
              <div className={classnames('ressources-content', { 'd-flex justify-content-center align-items-center': this.state.loading })}>
                <i className={classnames('fas fa-3x fa-circle-notch', { 'fa-spin': this.state.loading, 'd-none': !this.state.loading })}></i>
                <Row className={classnames({ 'd-none': this.state.loading })}>
                  {this.state.pages.map((page) => (
                    <Col md={6} lg={4} sm={12} key={page.id} className="mb-3">
                      <Card className='card2'>
                        <Card.Body>
                          <a href={'/pages/' + page.slug} className="text-decoration-none">
                            <div className="resource-img float-left">
                              <img src={this.findPageIcone(page.icone)} />
                            </div>
                            <div className="d-flex align-items-lg-center h-100">
                              <h6>{page.titre}</h6>
                            </div>
                          </a>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
};
