import React, { useState } from 'react';
import { Image, Nav, Navbar, NavDropdown, Row, Col } from 'react-bootstrap';
import Cookie from 'js-cookie';
import MenuService from '../services/MenuService';

class Header extends React.Component {
  state = {
    lang: Cookie.get('lang') || 'fr',
    menus : []
  };

  constructor() {
    super();

    this.fetchMenus();
  }

  async fetchMenus() {
    const menus = await MenuService.getTop();
    document.body.classList.add('loaded')
    this.setState({ menus });
  }

  setLang(lang) {
    if (this.state.lang === lang) {
      return;
    }

    Cookie.set('lang', lang);
    if (lang !== 'fr') {
      Cookie.set('googtrans', '/fr/' + lang);
    } else {
      Cookie.remove('googtrans')
    }
    this.setState({ lang }, () => {
      window.location.reload();
    });
  }

  render() {
    return (
      <Row className="no-gutters">
        <Col md={12}>
          <Navbar sticky="top" collapseOnSelect expand="md" variant="dark">
            <Navbar.Brand>
              <a href="/"><Image src="/logo-white.png" className="logo"></Image></a>
            </Navbar.Brand>

            <Navbar.Toggle aria-controls="responsive-navbar-nav" />

            <Navbar.Collapse id="responsive-navbar-nav">

              <Nav className="ml-auto">
                <Nav.Link className="active" href="/">Accueil</Nav.Link>
                { this.state.menus.map((m, indexMenu) => {
                  if (m.pages.length > 0) {
                    if (indexMenu === (this.state.menus.length - 1)) {
                      return (
                        <NavDropdown title={m.nom} key={m.id} alignRight>
                          {m.pages.map(p => {
                            return <NavDropdown.Item key={p.id} href={`/pages/${p.slug}`}>{p.titre}</NavDropdown.Item>
                          })}
                        </NavDropdown>
                      )
                    } else {
                      return (
                        <NavDropdown title={m.nom} key={m.id}>
                          {m.pages.map(p => {
                            return <NavDropdown.Item key={p.id} href={`/pages/${p.slug}`}>{p.titre}</NavDropdown.Item>
                          })}
                        </NavDropdown>
                      )
                    }
                  } else {
                    return (<Nav.Link href={m.lien} key={m.id}>{m.nom}</Nav.Link>);
                  }
                }) }

                <NavDropdown title={this.state.lang} id="basic" alignRight className="notranslate">
                  <NavDropdown.Item onClick={() => this.setLang('fr')}>Français</NavDropdown.Item>
                  <NavDropdown.Item onClick={() => this.setLang('en')}>English</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Col>
      </Row>
    );
  }
}

export default Header;
