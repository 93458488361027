import "../styles/Banner/RapportInfoBanner.css";

import React from "react";
import {Container, Card, Row, Col} from 'react-bootstrap';
import '../styles/Banner/RapportInfoBanner.css';
import classnames from 'classnames';
import {RapportInfoBanner} from '../components/Banners/RapportInfoBanner';
import { getEntity } from "../services/api";
import BackToTop from '../components/BackToTop';

const API = process.env.REACT_APP_API_BASE_URL;

export class Rapports extends React.Component {
  state = {
    thematiques: {},
    loading: true
  };

  constructor(props) {
    super(props);

    this.loadRapports();
  }

  loadRapports() {
    getEntity('rapports?include[]=thematique&include[]=fichier&all=true').then((responseData) => {
      const rapports = responseData.data.data;
      const thematiques = {};

      rapports.forEach((rapport) => {
        if (!thematiques[rapport.thematique.nom]) {
          thematiques[rapport.thematique.nom] = [];
        }

        thematiques[rapport.thematique.nom].push(rapport);
      });
      this.setState({ thematiques });
    }).finally(() => this.setState({ loading: false }));
  }

  fileFont(filename) {
    switch(true) {
      case filename.endsWith('.pdf'):
        return 'fa-file-pdf';
      case filename.endsWith('.doc') || filename.endsWith('.docx'):
        return 'fa-file-word';
      default:
        return 'fa-file-alt'
    }
  }

  renderThematiqueRapports(thematiques) {
    const jsx = [];
    for(const nom in thematiques) {
      jsx.push(
        <div key={nom}>
          <h4 className="title-bordered to-left">{nom}</h4>
          <Row>
            {thematiques[nom].map(r => {
              return (
                <Col md={3} key={r.id}>
                  <Card className="mb-3 text-center">
                    <Card.Body>
                      <i className={`fas fa-3x ${this.fileFont(r.fichier.filename)}`} ></i>
                      <Card.Title>{r.nom} (Année {r.annee})</Card.Title>
                      <a className="btn btn-primary" target="_blank" rel="noopener noreferrer" href={r.fichier.url}>
                        Télécharger <i className="fas fa-file-download"></i>
                      </a>
                    </Card.Body>
                  </Card>
                </Col>
              )
            })}
          </Row>
        </div>
      )
    }

    return jsx;
  }

  render() {
    return (
      <div className="Background pb-4 rapports-page container-fluid">
        <RapportInfoBanner title="Dossiers Eaux-Bénin. Parcourez les rapports sur l'eau par thématique"/>

        <Container className="text-form display-flex-center page-body">
          <Card className="shadow p-3 mb-5 bg-white rounded">

            <Card.Body className={classnames({'d-flex justify-content-center align-items-center': this.state.loading})}>
              <i className={classnames('fas fa-3x fa-circle-notch', { 'fa-spin': this.state.loading, 'd-none': !this.state.loading })}></i>
              <div className={classnames({'d-none': this.state.loading})}>
              {this.renderThematiqueRapports(this.state.thematiques)}
              </div>
            </Card.Body>
          </Card>
        </Container>
        <BackToTop/>
      </div>
    );
  }
}
