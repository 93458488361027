import { get } from './api';

const BASE_URL = '/search';

export default {
  find(query, add = [], limit = 50) {
    return get(BASE_URL, {
      params: {
        q: query,
        limit,
        add,
      }
    });
  },
};
