import React, { useState } from 'react';
import { Nav } from 'react-bootstrap';

export default function LetterSelector({letterSelected}) {
  const [selectedTab, setSelectedTab] = useState('')
  const handleSelect = (eventKey) => {
    setSelectedTab(eventKey)
    letterSelected(eventKey);
  };

  return (
    <Nav defaultActiveKey={selectedTab} onSelect={handleSelect} className="custom-navs alphabet-selector">
      <Nav.Item><Nav.Link eventKey="">Tout</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="A">A</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="B">B</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="C">C</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="D">D</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="E">E</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="F">F</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="G">G</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="H">H</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="I">I</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="J">J</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="K">K</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="L">L</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="M">M</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="N">N</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="O">O</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="P">P</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="Q">Q</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="R">R</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="S">S</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="T">T</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="U">U</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="V">V</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="W">W</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="X">X</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="Y">Y</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="Z">Z</Nav.Link></Nav.Item>
    </Nav>
  );
}

