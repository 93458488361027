import React from "react";
import  '../styles/backToTop.css';
import ScrollToTop from "react-scroll-to-top";
import "react-scroll-to-top/lib/index.css";

export default function BackToTop() {
  return (
    <div className="all">
      <ScrollToTop smooth top="800" className="backToTop" color="white" style={{'backgroundColor': '#ee9f30'}}/>
    </div>
  );
}