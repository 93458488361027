import React from "react";
import AreaSelector from '../components/area-selector/AreaSelector' ;
import Container from 'react-bootstrap/Container';
import { HomeBannerSingle } from '../components/home/BannerSingle';
import BackToTop from '../components/BackToTop';

export function Geographies() {
  return (
      <div id="pg-RegulationIndicateurNormes">
        <HomeBannerSingle />

        <Container className="mt-5">
          <h2 className="my-3">Les départements, communes, arrondissements et villages</h2>
          <AreaSelector></AreaSelector>
        </Container>
        <BackToTop/>
      </div>
    );
}
