import React, { useEffect, useState } from 'react';
import DepartementService from '../../services/DepartementService';
import ListingWithFilter from '../ListingWithFilter';

export default function DepartmentSelectorTab({handleDepartementSelected, query}) {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadDepartments();
  }, []);

  const loadDepartments = async () => {
    const res = await DepartementService.getAll();
    setDepartments(res.data.data);
    setLoading(false);
  }

  return (
    <ListingWithFilter data={departments} handleItemSelected={handleDepartementSelected} query={query} loading={loading}></ListingWithFilter>
  );
}
