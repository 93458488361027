import React from 'react';
import { Image, Col, Row, Nav } from 'react-bootstrap';
import MenuService from '../services/MenuService';

class Footer extends React.Component {
  state = {
    menus : []
  };

  constructor() {
    super();

    this.fetchMenus();
  }

  async fetchMenus() {
    const menus = await MenuService.getBottom();
    this.setState({ menus });
  }

  render() {
    return (
      <footer className="footer">
        <Row >
          <Col md={12}>
            <Row>
              <Col md={3}>
                <Image src="/logo-white.png" alt="armoiries" className="logo" />
              </Col>
              <Col md={9} className="d-flex align-items-center justify-content-end">
                <Nav>
                  { this.state.menus.map(m => {
                    return (
                      <Nav.Item key={m.id}>
                        <Nav.Link href={m.lien}>{m.nom}</Nav.Link>
                      </Nav.Item>
                    )
                  }) }
                  <Nav.Item>
                    <Nav.Link href="/guide">Guide d'utilisation</Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link href="https://web.facebook.com">
                      <i className="fab fa-lg fa-facebook"></i>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="https://www.twitter.com">
                      <i className="fab fa-lg fa-twitter"></i>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </footer>
    )
  }
}

export default Footer;
