import React, { Component } from "react";
import { Link } from 'react-router-dom';

import {
  Carousel,
  Col,
  Container,
  Row
} from "react-bootstrap";
import ActualiteService from '../../services/ActualiteService';

export class NewsSlider extends Component {
  state = {
    actualites: []
  };

  async componentDidMount() {
    const res = await ActualiteService.getMains()
    this.setState({actualites: res.data.data})
  }

  stripHtml(html) {
    var tmp = document.createElement('DIV');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

  render() {
    return (
      <div className="home-more-resources">
        <Container>
          <Row className="no-gutters">
            <Col md={12}>
              <h2 className="title-bordered to-left">Actualités | Bulletins D'information</h2>

              <Carousel interval="7000" className="actualites-carousel">
                { this.state.actualites.map((actualite) => {
                  return <Carousel.Item key={actualite.id}>
                    <div className="news-item" style={{ backgroundImage: `url(${(actualite.image || {}).url})` }}></div>
                    <Carousel.Caption>
                      <h3>
                        <Link to={'/actualites/' + actualite.slug} className="text-white text-decoration-none">
                          {actualite.titre}
                        </Link>
                      </h3>
                    </Carousel.Caption>
                  </Carousel.Item>
                }) }

              </Carousel>

            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
