import "../../styles/Banner/RapportInfoBanner.css";

import React from "react";

import {
  Col,
  Row
} from "react-bootstrap";

export function RapportInfoBanner({ title }) {
  return (
    <Row>
      <Col className="BannerColor text-center justify-content-center pt-3 pb-5 info-banner" md={12}>
        <h2>{title ? title : 'Publié sur EauBenin'}</h2>
      </Col>
    </Row>
  );
}
