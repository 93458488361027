import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Badge, Button, InputGroup } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import {
  Highlighter,
  Menu,
  MenuItem,
  Typeahead
} from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import SearchService from '../services/SearchService';

const queryString = require('query-string');

export function SearchBox() {
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [items, setItems] = useState([])
  const history = useHistory();

  useEffect(() => {
    loadItems();
  }, []);

  useEffect(() => {
    loadItems();
  }, [searchQuery]);

  function parseQueryParams() {
    const query = queryString.parse(window.location.search, {arrayFormat: 'brackets'});

    let indicateur = [];
    let area_id = [];
    let area_type = [];

    if (query.indicateur) {
      indicateur = Array.isArray(query.indicateur)
        ? query.indicateur : [query.indicateur];
      delete query.indicateur
      indicateur = indicateur.length > 2
        ? indicateur.slice(indicateur.length -2) : indicateur
    }

    if (query.area_id && query.area_type) {
      area_id = Array.isArray(query.area_id)
        ? query.area_id : [query.area_id];
      area_type = Array.isArray(query.area_type)
        ? query.area_type : [query.area_type];

      if(area_type.length < area_id.length) {
        area_id = area_id.slice(0, area_type.length)
      } else if (area_type.length > area_id.length) {
        area_type = area_type.slice(0, area_id.length)
      }

      delete query.area_id
      delete query.area_type
    }

    return {...query, indicateur, area_id, area_type};
  }

  function getUids() {
    const parsed = parseQueryParams()

    if (parsed.indicateur.length == 0 && parsed.area_id.length == 0) {
      return [];
    }

    let selection = [];
    parsed.indicateur.map(item => selection.push(`indicateurs-${item}`));
    parsed.area_id.map((item, index) => selection.push(`${parsed.area_type[index]}-${item}`));

    return selection;
  }

  const loadItems = async () => {
    const selectedUids = getUids();

    if (searchQuery.length == 0 && selectedUids.length == 0) {
      setItems([]);
      return;
    }

    const res = await SearchService.find(searchQuery, selectedUids)
    const options = res.data.data
    setItems(options);
    const selectedItems = options.filter(item => selectedUids.includes(item.uid));
    setSelectedItems(selectedItems);
  }

  const handleInputChange = (q) => {
    setSearchQuery(q)
  }

  const debouncedHandleInputChange = debounce(handleInputChange, 300)

  const onItemChange = (data) => {
    setSelectedItems(data);

    let parsed = parseQueryParams();
    parsed.indicateur = []
    parsed.area_id = []
    parsed.area_type = []

    data.map(item => {
      if (item.type == 'indicateurs') {
        parsed.indicateur.push(item.id)
      } else if (item.type == 'thematiques') {
        parsed.thematique = item.id
      } else {
        parsed.area_id.push(item.id)
        parsed.area_type.push(item.type)
      }
    })

    if(parsed.indicateur.length > 2) {
      parsed.indicateur = parsed.indicateur.slice(parsed.indicateur.length - 2)
    }

    let url = '';
    if (parsed.indicateur.length == 0) {
      url = 'indicateurs';
    } else if (parsed.area_id.length == 0) {
      url = 'geographies';
    } else {
      url = 'analyse';
    }

    const fullUrl = `/${url}?` + queryString.stringify({
      ...parsed,
    }, {arrayFormat: 'brackets'})

    history.push(fullUrl);
  }

  return (
    <InputGroup size="lg" className="search">
      <InputGroup.Prepend>
        <InputGroup.Text id="basic-addon1">
          <i className="fas fa-search"></i>
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Typeahead
        id="mainSearchBox"
        labelKey="name"
        multiple={true}
        onInputChange={debouncedHandleInputChange}
        onChange={onItemChange}
        options={items}
        placeholder="Choisissez un indicateur ou un lieu à analyser"
        renderMenu={(results, menuProps) => (
          <Menu {...menuProps}>
            {results.map((result, index) => (
              <MenuItem key={result.uid} option={result} position={index}>
                <Badge variant="secondary" style={{marginRight: '5px'}}>{result.type.slice(0, 3).toUpperCase()}</Badge>
                <Highlighter search={searchQuery}>{result.name}</Highlighter>
              </MenuItem>
            ))}
          </Menu>
        )}
        selected={selectedItems}
      />
      <InputGroup.Append>
        <Button variant="outline-secondary">
          <i className="fas fa-chevron-down"></i>
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
}
;
