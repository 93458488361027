import React from "react";
import { HomeBanner } from '../components/home/Banner';
import { HomeSearch } from '../components/home/Search';
import { HomeCards } from '../components/home/Cards';
import { HomeStaticCards } from '../components/home/StaticCards';
import { HomeMoreResources } from '../components/home/MoreResources';
import { HomePartners } from '../components/home/Partners';
import { DownloadApp } from '../components/home/DownloadApp';
import { NewsSlider } from "../components/home/NewsSlider";
import BackToTop from '../components/BackToTop';

export function Home() {
  return (
    <div>
      <HomeBanner />

      <HomeSearch />

      <HomeMoreResources />

      <NewsSlider />

      <HomeCards />

      <HomeStaticCards />

      <HomePartners />

      <BackToTop />

      <DownloadApp />
    </div>
  );
};
