import React, { useEffect, useState } from 'react';
import { Card, Col, ListGroup, Row } from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';
import { Link } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import IndicateurService from '../../services/IndicateurService';
import ActualiteService from '../../services/ActualiteService';

import Moment from 'react-moment';
import classnames from 'classnames';

export function HomeCards() {
  const [recentIndicators, setRecentIndicators] = useState([])
  const [recentActualites, setRecentActualites] = useState([])
  const [discoverIndicator, setDiscoverIndicator] = useState(null)
  const [loadingRecent, setLoadingRecent] = useState(true)
  const [loadingPopular, setLoadingPopular] = useState(true)
  const [loadingDiscover, seLoadingDiscover] = useState(true)

  async function loadRecentIndicators() {
    const res = await IndicateurService.getRecents()
    setRecentIndicators(res.data.data)
    setLoadingRecent(false);
  }

  async function loadRecentActualites() {
    const res = await ActualiteService.getRecents();
    setRecentActualites(res.data.data)
    setLoadingPopular(false);
  }

  async function loadDiscoverAnalyse() {
    if (recentActualites.length === 0) {
      return;
    }

    const res = await IndicateurService.analyse(recentActualites[0].id)

    const analyse = res.data.data

    const results = analyse.reduce((acc, item) => {
      acc.labels.push((new Date(item.key)).getFullYear());
      acc.values.push(item.value)

      return acc;
    }, {
      labels: [],
      values: []
    })

    const indicateur = res.data.indicateur

    const chartData = {
      labels: results.labels,
      datasets: [{
        label: indicateur.nom,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgb(255, 99, 132)',
        data: results.values,
        fill: false,
        pointRadius: 10,
        pointHoverRadius: 15,
        showLine: false
      }]
    }

    const chartOptions = {
      responsive: true,
      title: {
        display: true,
        text: indicateur.nom
      },
      legend: {
        display: false
      },
      elements: {
        point: {
          pointStyle: 'triangle'
        }
      }
    }

    setDiscoverIndicator({
      analyse,
      indicateur,
      chartData,
      chartOptions
    });
    seLoadingDiscover(false);
  }

  useEffect(() => {
    loadRecentIndicators();
    loadRecentActualites();
  }, [])

  useEffect(() => {
    loadDiscoverAnalyse()
  }, [recentActualites])

  return (
    <div className="home-cards container">
      <Row className="justify-content-center">
        <Col md={4}>
          <Card className={classnames('shadow-sm', { 'd-flex justify-content-center align-items-center': loadingRecent })}>
            <i className={classnames('fas fa-2x fa-circle-notch', { 'fa-spin': loadingRecent, 'd-none': !loadingRecent })}></i>
            <Card.Body className={classnames({ 'd-none': loadingRecent })}>
              <Card.Title>
                Actualités récentes
              </Card.Title>
              <ListGroup className="list-group-flush">
                {recentActualites.map(actualite => (
                  <ListGroup.Item key={actualite.id}>
                    <Link to={'/actualites/' + actualite.slug}>
                      <span className="item-title">{actualite.titre}</span>
                      <span className="item-desc">
                        <Moment format="YYYY/MM/DD">{actualite.updated_at}</Moment>
                        <i className="ml-1 fas fa-external-link-alt"></i></span>
                    </Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className={classnames('shadow-sm', { 'd-flex justify-content-center align-items-center': loadingDiscover })}>
            <i className={classnames('fas fa-2x fa-circle-notch', { 'fa-spin': loadingDiscover, 'd-none': !loadingDiscover })}></i>
            <Card.Body className={classnames({ 'd-none': loadingDiscover })}>
              <Card.Title>A Découvrir</Card.Title>
              {discoverIndicator && (
                <Line data={discoverIndicator.chartData} options={discoverIndicator.chartOptions} height={280} />
              )
              }
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className={classnames('shadow-sm', { 'd-flex justify-content-center align-items-center': loadingPopular })}>
            <i className={classnames('fas fa-2x fa-circle-notch', { 'fa-spin': loadingPopular, 'd-none': !loadingPopular })}></i>
            <Card.Body className={classnames({ 'd-none': loadingPopular })}>
              <Card.Title>
                Données récentes
              </Card.Title>
              <ListGroup className="list-group-flush">
                {recentIndicators.map(indicateur => (
                  <ListGroup.Item key={indicateur.id}>
                    <Link to={`/geographies?indicateur=${indicateur.id}`}>
                      <span className="item-title">{indicateur.nom}</span>
                      <span className="item-desc">
                        <Moment format="YYYY/MM/DD">{indicateur.updated_at}</Moment>
                        <i className="ml-1 fas fa-external-link-alt"></i></span>
                    </Link>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
