import React from 'react';
import {
    Card,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Nav,
    Row,
    Form
} from 'react-bootstrap';
import Un from './guideImages/1.PNG';
import Deux from './guideImages/2.PNG';
import Trois from './guideImages/3.PNG';
import './style.css';



export default function Acceder() {

	return (

			<div>
				<Card><img src={Un} /><br/>

				<p className="offset-1 col-10">Ouvrir une nouvelle fenêtre dans le navigateur de votre équipement de connexion puis<br/>
				saisir l’URL : <a href="https://ressources-eau.gouv.bj">https://ressources-eau.gouv.bj</a> et validez</p>

				<img src={Deux} /><br/>

				<p className="offset-1 col-10">Une fois sur la plateforme vous avez le bouton ‘’En savoir plus sur Eau Bénin’’
				 <br/>qui vous mène vers la page présentant les informations sur «Eau Bénin » :</p>	

				 <img src={Trois} /><br/>


				</Card>
				
			</div>

			);
		}