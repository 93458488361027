import React, { Component } from "react";
import {
  Card,
  Col,
  Container,
  ListGroup,
  ListGroupItem,
  Nav,
  Row,
  Form
} from 'react-bootstrap';
import './style.css';


export default class PointEau extends Component {

  continue = e => {
    e.preventDefault();
    this.props.nextStep();
  }

  back = e => {
    e.preventDefault();
    this.props.prevStep();
  }

  render() {

    const { values } = this.props;

    return (
      <>
        <Row>
          <Col md={6} sm={10} xs={8}>
            <Form.Group >
              <Form.Label style={{ fontSize: '25px', fontWeight: 'bold' }}>Type de point d'eau <abbr title="Ce champs est requis">*</abbr></Form.Label>
              <Form.Control as="select" size="lg" defaultValue={values.typepoint} name="typepoint" onChange={this.props.handleChange('typepoint')} >
              <option selected hidden>Choisir un point d'eau</option>
                <option>pompe motrice</option>
                <option >branchement Privé</option>
                <option>borne fontaine</option>
                <option>château d'eau</option>
              </Form.Control>
            </Form.Group>
          </Col>

          <Col style={{ marginTop: '45px' }}>

          </Col>
        </Row>
        <p>
          <button type="button"  className="btn btn-primary btn-lg" onClick={this.back} ><i className="fa fa-angle-left" aria-hidden="true"></i> Retour</button>
          <button type="button" className="btn btn-outline-primary btn-lg ml-3" onClick={this.continue} > Suivant <i className="fa fa-angle-right" aria-hidden="true"></i></button>
        </p>
      </>

    );
  }

};
