import React from 'react';
import './style.css';

export default function Prerequis() {

	return (

			<div>
				<p>Pour accéder à la plateforme de digitalisation du système de l’eau, vous devez :</p>
				<ul>
				<li>Disposer d’un ordinateur</li>
				<li>Avoir une connectivité au réseau d’un opérateur</li>
				</ul>
				
			</div>
		);
}