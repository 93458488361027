import "../styles/Banner/RapportInfoBanner.css";

import React from "react";
import BackToTop from '../components/BackToTop';

import classnames from "classnames";
import {
  Card,
  Container
} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import { RapportInfoBanner } from "../components/Banners/RapportInfoBanner";
import PartenaireService from "../services/PartenaireService";

export class Partenaire extends React.Component{
  state = {
    financiers: [],
    techniques: [],
    partenaires: { },
    loadingPartenaires: { programme: true, financier: true, technique: true, commercial: true, institution: true },
    loadingFinanciers: true,
    loadingTechniques: true,
  };
  typesPartenaire = [
    { value: 'structure_centrale', label: 'Structures centrales' },
    { value: 'acteur_eau', label: 'Acteurs de l\'eau' },
    { value: 'programme', label: 'Programmes et projets' },
    { value: 'structure_sectorielle', label: 'Structures sectorielles partenaires' },
    { value: 'technique_financier', label: 'Partenaires' },
    { value: 'civil', label: 'Organisations Civiles' },
  ];

  constructor(props) {
    super(props);

    this.loadPartenaires();
  }

  loadPartenaires() {
    this.typesPartenaire.forEach(typeObject => {
      ((_typeObject) => {
        const type = _typeObject.value;
        PartenaireService.getType(type).then((responseData) => {
          const financiers = responseData.data.data;
          this.setState({
            partenaires: { ...this.state.partenaires, ...{ [type]: financiers, } }
          });
        }).finally(() => this.setState({ loadingPartenaires: { ...this.state.loadingPartenaires, ...{[type]: false} } }));
      })(typeObject);
    });
  }

  render() {
    return (
      <div className="Background pb-4 statique-page container-fluid">
        <RapportInfoBanner title="Les acteurs" />

        <Container className="text-form display-flex-center page-body">
          <Card className="shadow p-3 mb-5 bg-white rounded">

            <Card.Body>
              {
                this.typesPartenaire.map(typeObject => {
                  if (this.state.partenaires[typeObject.value]?.length == 0) {
                    return <></>;
                  }

                  return <>
                    <h4 className="title-bordered to-left">{ typeObject.label }</h4>
                    <div className={classnames({'d-flex justify-content-center align-items-center': this.state.loadingPartenaires[typeObject.value]})}>
                      <i className={classnames('fas fa-3x fa-circle-notch', { 'fa-spin': this.state.loadingPartenaires[typeObject.value], 'd-none': !this.state.loadingPartenaires[typeObject.value] })}></i>
                      <div className={classnames('row page-partners', {'d-none': this.state.loadingPartenaires[typeObject.value]})}>
                        {this.state.partenaires[typeObject.value]?.map((partenaire) => (
                            <Col md={4} key={partenaire.id}>
                              <Card>
                                <Card.Img variant="top" src={partenaire.logo.url} />
                                <Card.Body>
                                  <Card.Title className="title-bordered to-left title-w100 title-light">{ partenaire.name }</Card.Title>
                                  <Card.Text>
                                    <span dangerouslySetInnerHTML={{ __html: partenaire.description }}></span>
                                  </Card.Text>
                                  <a href={partenaire.link} target={partenaire.link?.startsWith('http') ? '_blank' : ''} className="stretched-link"></a>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                      </div>
                    </div>
                  </>
                })
              }
            </Card.Body>
          </Card>
        </Container>
        <BackToTop/>
      </div>
    );
  }
}
