import React from "react";
import ReactGA from 'react-ga';

import {
  BrowserRouter as Router,
  Route,
  withRouter
} from "react-router-dom";

import Footer from "./layouts/Footer";
import Header from "./layouts/Header";
import {
  Analyse,
  Geographies,
  Home,
  Indicateurs,
  Informations,
  Page,
  Rapports,
  Suggestion,
  Contact,
  Actualite,
  ApprovisionnementEau,
  Guide,
  Partenaire
} from "./pages";

const SwitchRoutes = (props) => {
  return (
    <>
      <Header/>

      <Route path="/" exact component={Home} />
      <Route path="/geographies" exact component={Geographies} />
      <Route path="/indicateurs" exact component={Indicateurs} />
      <Route path="/analyse" exact component={Analyse} />
      <Route path="/rapports" exact component={Rapports}/>
      <Route path="/suggestion" exact component={Suggestion}/>
      <Route path="/informations" exact component={Informations}/>
      <Route path="/pages/:slug" exact component={Page}/>
      <Route path="/actualites/:slug" exact component={Actualite}/>
      <Route path="/contact" exact component={Contact}/>
      <Route path="/procedure" exact component={ApprovisionnementEau}/>
      <Route path="/guide" exact component={Guide}/>
      <Route path="/partenaires" exact component={Partenaire}/>
      <Footer />
    </>
  )
}
const SwitchRoutesWithRouter = withRouter(SwitchRoutes)

const App = () => {

  React.useEffect( () => {
    ReactGA.initialize('UA-172967291-2')

    ReactGA.pageview(window.location.pathname + window.location.search)

  }, [window.location]);




  return (
    <Router>
      <SwitchRoutesWithRouter/>
    </Router>
  );
}

export default App;
