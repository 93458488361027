import React, { useEffect, useState } from 'react';
import ThematiqueService from '../../services/ThematiqueService';
import { Button, Col, Row } from 'react-bootstrap';

export default function TousLesIndicateurs({searchQuery, thematiqueIds, handleIndicateurSelected}) {
  const [thematiques, setThematiques] = useState([])
  const [filteredThematiques, setFilteredThematiques] = useState([])

  useEffect(() => {
    loadIndicateurs();
  }, []);

  useEffect(() => {
    filterItems()
  }, [thematiques, searchQuery, thematiqueIds]);

  const loadIndicateurs = async () => {
    const res = await ThematiqueService.getAllWithIndicateurs();
    const data = res.data.data

    const sortedItems = data.map(thematique => {
      return {
        ...thematique,
        indicateurs: thematique.indicateurs.sort((a, b) => a.nom.localeCompare(b.nom))
      }
    })

    setThematiques(sortedItems);
  }

  const filterItems = () => {
    const items = thematiqueIds.length === 0
      ? thematiques
      : thematiques.filter(item => thematiqueIds.includes(item.id));

    if (!searchQuery) {
      setFilteredThematiques(items);
      return
    }

    const searchedItems = thematiques.map(thematique => {
      return {
        ...thematique,
        indicateurs: thematique.indicateurs.filter(item => item.nom.toLowerCase().includes(searchQuery))
      }
    })

    setFilteredThematiques(searchedItems);
  }

  return (
    <div>
      {filteredThematiques.map(thematique => (thematique.indicateurs.length > 0 && (
          <div key={thematique.id} className="my-5">
            <h3>{thematique.nom}</h3>
            <Row className="thematique-indicateurs">
              {thematique.indicateurs.map(indicateur => (
                <Col md={6} key={indicateur.id} className='indicateur my-2 pr-5'>
                  <Button variant="link" className="item my-0 p-0 text-left text-decoration-none font-weight-bold" key={indicateur.id}
                    onClick={() => handleIndicateurSelected(indicateur)}>{indicateur.nom}</Button>
                </Col>
              ))}
            </Row>
          </div>
        )
      ))}
    </div>
  );
}
