import { get } from './api';
import QueryStringService from './QueryStringService';

const BASE_URL = '/thematiques';

export default {
  getForHomePage() {
    return this.getList(
      true,
      null,
      {
        accueil: {
          op: '=',
          value: true
        }
      },
      [],
      {});
  },
  getMains() {
    return this.getList(
      null,
      5,
      {},
      ['indicateurs'],
      {});
  },

  getAllWithIndicateurs() {
    return this.getList(
      true,
      null,
      {},
      ['indicateurs'],
      {});
  },

  getList(all = false, perPage = 15, filter = {}, include = [], sort = {}) {
    let params = QueryStringService.build({
      filter, include, sort
    }, all, perPage)

    return get(BASE_URL, {
      params
    });
  }
};
