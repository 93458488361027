import React, { useState } from "react";
import { InputGroup, Button, FormControl } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

export function SearchBoxLight({handleQueryChanged}) {

  return (
    <InputGroup className="mb-3" className="search-box-light">
      <InputGroup.Prepend>
        <svg width="30" height="35" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M11.1429 0.833374C17.2969 0.833374 22.2857 6.37651 22.2857 13.2143C22.2857 16.281 21.2743 19.1 19.6114 21.2715L20.0743 21.7858H21.4286L30 31.3096L27.4286 34.1667L18.8571 24.6429V23.1381L18.3943 22.6238C16.44 24.4715 13.9029 25.5953 11.1429 25.5953C4.98883 25.5953 0 20.0521 0 13.2143C0 6.37651 4.98883 0.833374 11.1429 0.833374ZM11.1429 4.64282C6.85716 4.64282 3.42859 8.45234 3.42859 13.2142C3.42859 17.9761 6.85716 21.7856 11.1429 21.7856C15.4286 21.7856 18.8572 17.9761 18.8572 13.2142C18.8572 8.45234 15.4286 4.64282 11.1429 4.64282Z" fill="#5A5A5A"/>
        </svg>
      </InputGroup.Prepend>
      <FormControl onChange={(e) => handleQueryChanged(e.currentTarget.value)}
        placeholder="Filtrer ..."
      />
    </InputGroup>
  );
};
