import React from 'react';

import {
	Card,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Nav,
	Row,
	Form,
	Accordion,
	Button
} from 'react-bootstrap';
import './style.css';

import Un from './guideImages/44.PNG';
import Deux from './guideImages/45.PNG';

export default function Footer() {

	return (

			<div>
				<p>1. Pour faire une suggestion, aller dans le pied de page
				 et cliquez sur ‘’Faire une suggestion’’ et vous remplissez le formulaire puis soumettez.</p>

				  <img src={Un} /><br/>

				<p>2. Pour accéder à la page de contact cliquez sur ‘’Contact’’ dans le pied de page.</p>

				 <img src={Deux} /><br/>

			</div>

			);
		}