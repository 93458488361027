import React from 'react';
import {
	Card,
	Col,
	Container,
	ListGroup,
	ListGroupItem,
	Nav,
	Row,
	Form,
	Accordion,
	Button
} from 'react-bootstrap';
import './style.css';

import Un from './guideImages/4.PNG';
import Deux from './guideImages/5.PNG';
import Trois from './guideImages/6.PNG';
import Quatre from './guideImages/7.PNG';
import Cinq from './guideImages/8.PNG';
import Six from './guideImages/9.PNG';
import Sept from './guideImages/10.PNG';
import Huit from './guideImages/11.PNG';
import Neuf from './guideImages/12.PNG';
import Dix from './guideImages/13.PNG';
import Onze from './guideImages/14.PNG';
import Douze from './guideImages/15.PNG';
import Treize from './guideImages/16.PNG';
import Quatorze from './guideImages/17.PNG';
import Quinze from './guideImages/18.PNG';
import Seize from './guideImages/19.PNG';
import DixSept from './guideImages/20.PNG';
import DixHuit from './guideImages/21.PNG';
import DixNeuf from './guideImages/22.PNG';
import Vingt from './guideImages/23.PNG';
import VingtUn from './guideImages/24.PNG';
import VingtDeux from './guideImages/25.PNG';
import VingtTrois from './guideImages/26.PNG';
import VingtQuatre from './guideImages/27.PNG';
import VingtCinq from './guideImages/28.PNG';
import VingtSix from './guideImages/29.PNG';
import VingtSept from './guideImages/30.PNG';
import VingtHuit from './guideImages/31.PNG';

import gire0 from './guideImages/gire0.PNG';
import gire1 from './guideImages/gire1.PNG';
import gire2 from './guideImages/gire2.PNG';
import gire3 from './guideImages/gire3.PNG';



export default function Prerequis() {

	return (

			<div>
				<p><b>Pour faire une analyse de données il est possible de partie d’une thématique,
				<br/> d’un indicateur ou d’une zone géographique</b>
				<br/><br/> 	L'analyse des données est affichée sous quatre (04) formes de représentation graphiques :
				 linéaire, histogramme, carte géographique et tableau</p>

				 <Accordion defaultActiveKey="0">
			<Card>
			<Card.Header>
			<Accordion.Toggle as={Button} variant="link" eventKey="0">
			<h5> 1. Zone géographique :</h5>
			</Accordion.Toggle>
			</Card.Header>
			<Accordion.Collapse eventKey="0">
			<Card.Body>
				
				<p><br/><br/>
				Pour analyser un indicateur ou une zone géographique vous revenez sur la page d’accueil
				 <br/>et vous scrollez une fois vers le haut pour avoir la barre de recherche suivante :</p>

				 <img src={Un} /><br/>

				 <p>Lorsque vous Cliquez dans la barre de recherche vous pouvez effectuer une recherche
				  <br/>par indicateur ou par zone géographique :</p>	

				  <img src={Deux} /><br/>

				  <p>Lorsque vous choisissez une zone géographique, vous êtes automatiquement redirigé
				   <br/>vers la page d’affichage des indicateurs:</p>	

				   <img src={Trois} /><br/>

				   <img src={Quatre} /><br/>

				   <p>Pour filtrer par indicateur, cliquez dans le champ ‘’Filtrer’’ situé en haut à droite
				    <br/>de la zone de sélection des indicateurs :</p>	

				    <img src={Cinq} /><br/>

				    <p>Vous pouvez donc choisir un indicateur pour être redirigé sur la page d’analyse de données</p>

				    <img src={Six} /><br/>	

				    <p>Dans la zone de sélection des zones géographiques se trouvant sur la page d’analyse vous pouvez 
				    <br/>choisir parmi les départements, communes, arrondissements et villages affichés.</p>

				    <img src={Sept} /><br/>

				    <p>Cette zone de sélection affiche par défaut la liste des départements. Pour avoir la liste des communes,
				     <br/>cliquez sur ‘’Commune’’ et pour avoir celle des arrondissements, cliquez sur ‘’Arrondissement’’.
				      <br/>Vous pouvez aussi filtrez par zone géographique :</p>

				      <img src={Huit} /><br/>

				    <p>Lorsque vous sélectionnez une zone géographique, vous avez les données de cette zone qui s’affiche sur les graphes.
				     <br/>Par défaut ces données s’affichent sur le diagramme linéaire : </p>

				     <img src={Neuf} /><br/>

				    <p>Vous pouvez aussi grouper l’affichage des données par jour, par mois ou par années en cliquant sur ‘’Grouper par’’
				     <br/>et varier aussi l’intervalle entre les années :</p>

				     <img src={Dix} /><br/>

				    <p>Pour afficher les données sur le diagramme en bâton, cliquez sur le bouton ‘’Histogramme’’ se trouvant juste à côté du bouton ‘’Linéaire’’.
				     <br/>Vous pouvez aussi choisir de grouper l’affichage des données par jour, par mois ou par années, par départements, par communes ou arrondissements
				      et varier l’intervalle entre les années :</p>

				      <img src={Onze} /><br/>

				    <p>Lorsque vous cliquez sur le bouton ‘’Carte’’ se trouvant juste à côté du bouton ‘’Histogramme’’,
				     <br/>les données de la zone géographique sélectionnée au préalable s’affiche sur une carte. Lorsque vous cliquez dans le champ ‘’Grouper’’,
				      <br/>vous avez les options d’affichage suivante : affichage détaillé, par communes, par départements, par arrondissements.</p>

				      <img src={Douze} /><br/>

				    <p>Lorsque vous cliquez sur le bouton ‘’tableau’’, les données vous seront affichées dans un tableau :</p>

				    <img src={Treize} /><br/>

				    <p>Vous avez aussi la possibilité de rajouter d’autre zones géographiques à celle choisit préalablement :</p>

				     <img src={Quatorze} /><br/>

				    <p>Pour ce faire vous cliquez dans la barre de recherche se situant sur la bannière 
				    <br/>et vous tapez les initiaux de votre zone géographique pour la voir s’afficher dans la liste:</p>

				     <img src={Quinze} /><br/>

				    <p>Lorsque vous choisissez un indicateur, vous serez redirigé vers la page de sélection de zones géographiques
				    <br/>(départements, communes, arrondissements) :</p>

				     <img src={Seize} /><br/>

				    <p>Cette zone de sélection affiche par défaut la liste des départements. Pour avoir la liste des communes, cliquez sur ‘’Commune’’ et pour avoir celle des arrondissements,
				     <br/>cliquez sur ‘’Arrondissement’’. Vous pouvez aussi filtrez par zone géographique :</p>

				      <img src={DixSept} /><br/>

				    <p>Pour télécharger les données affichées, choisissez le format de votre choix dans la zone de téléchargement suivante :</p><br/>

				     <img src={DixHuit} /><br/>

			</Card.Body>
			</Accordion.Collapse>
			</Card>

			<Card>
			<Card.Header>
			<Accordion.Toggle as={Button} variant="link" eventKey="1">
			<h5>2. Départements</h5>
			</Accordion.Toggle>
			</Card.Header>
			<Accordion.Collapse eventKey="1">
			<Card.Body>

			 <br/><br/>
				    <p>Pour effectuer une recherche en partant d'un département cliquez sur ‘’départements’’
				     <br/>se trouvant justes en dessous de la barre de recherche sur la page d’accueil :</p>

				      <img src={DixNeuf} /><br/>


				    <p>Lorsque vous cliquez sur ‘’département’’ vous serez redirigé sur la page de sélection des zones géographiques
				     <br/>(départements, communes, arrondissements, quartier):</p>

				      <img src={Vingt} /><br/>


				    <p>Lorsque vous choisissez une zone géographique vous êtes redirigé sur la page de choix des indicateurs.
				     <br/>Vous pouvez ainsi choisir un indicateur et afficher ces données.</p><br/>

				      <img src={VingtUn} /><br/>
				

			</Card.Body>
			</Accordion.Collapse>
			</Card>

			<Card>
			<Card.Header>
			<Accordion.Toggle as={Button} variant="link" eventKey="2">
			<h5>3. Indicateurs</h5>
			</Accordion.Toggle>
			</Card.Header>
			<Accordion.Collapse eventKey="2">
			<Card.Body>
				
				<br/><br/>

				    <p>Pour effectuer une recherche en partant d'un indicateur cliquez sur ‘’indicateurs’’ se trouvant justes en dessous
				     <br/>de la barre de recherche sur la page d’accueil :</p>

				      <img src={VingtDeux} /><br/>

				    <p>Une fois sur la page de choix d’un indicateur, lorsque vous choisissez un indicateur,</p>

				     <img src={VingtTrois} /><br/>

				    <p>vous êtes redirigé sur la page de sélection de zone géographique sur laquelle vous serez amené
				     <br/>à choisir une zone géographique</p>

				     <img src={VingtQuatre} /><br/>

				    <p> Et ainsi visualisez les données.</p>

				    <img src={VingtCinq} /><br/>

			</Card.Body>
			</Accordion.Collapse>
			</Card>

			<Card>
			<Card.Header>
			<Accordion.Toggle as={Button} variant="link" eventKey="3">
			<h5>4. Thématiques</h5>
			</Accordion.Toggle>
			</Card.Header>
			<Accordion.Collapse eventKey="3">
			<Card.Body>
				
				<br/><br/>

				    <p>Pour accéder aux informations publiées sur une thématique allez dans le menu de navigation et choisissez une thématique : </p>

				    <img src={VingtSix} /><br/>

				    <p>Lorsque vous choisissez une thématique, vous êtes redirigé sur la page de publications des informations liées à cette thématique :</p>

				    <img src={VingtSept} /><br/>

				    <p>Sur cette page, vous avez une section tout en bas de la page qui contient la liste des indicateurs liés à cette thématique
				     <br/>pour ainsi vous permettre de consulter les données de ces indicateurs :</p>

				     <img src={VingtHuit} /><br/><br/>


				     <p>Le menu "GIRE" vous permet d"acceder a différent sous menu dont <br/> la "Politique nationale de l'eau", "Vision eau Bénin", "Procédure nationale de l'eau" </p>

				    <img src={gire0} /><br/><br/>

				    <p className="text-weight-bold"><span>Politique nationale de l'eau</span></p>

				    <img src={gire1} /><br/><br/>

				    <p className="text-weight-bold">Vision eau Bénin</p>
				    
				     <img src={gire2} /><br/><br/>

				     <p className="text-weight-bold">Procédure nationale de l'eau</p>

				     <img src={gire3} /><br/>

			</Card.Body>
			</Accordion.Collapse>
			</Card>


			</Accordion>

			</div>
		);
}