import { get, post } from './api';
import QueryStringService from './QueryStringService';

const BASE_URL = 'menus';

export default {
  getTop() {
    return this.getList(
      {position: 'haut'},
      ['pages'],
      { ordre: 'asc' }
    );
  },

  getBottom() {
    return this.getList(
      {position: 'bas'},
      ['pages'],
      { ordre: 'asc' }
    );
  },

  getList(filter = {}, include = [], sort = {}) {
    let params = QueryStringService.build({
      filter, include, sort
    }, true)

    return get(BASE_URL, {
      params
    }).then(response => {
      let menus = response.data.data;

      menus = menus.map(m => {
        m.pages = m.pages.sort((a, b) => {
          if (a.created_at < b.created_at) {
            return -1;
          } else if (a.created_at < b.created_at) {
            return 1;
          } else {
            return 0
          }
        });

        return m;
      })
      return menus;
    });
  },
};
