import React from 'react';

const ICONES_STRING = [
    'agricole',
    'climat',
    'climate-change',
    'dossier',
    'drop-1',
    'drop',
    'eauSouterraine',
    'eauSurface',
    'map',
    'population-1',
    'population',
    'procedure',
    'process',
    'qe',
    'retenueau',
    'robinet',
    'security-1',
    'temperature',
    'thermometer',
    'wind',
    'Asset 14@0.5x',
    'Asset 15@0.5x',
    'Asset 16@0.5x',
    'Asset 17@0.5x',
    'Asset 18@0.5x',
    'Asset 19@0.5x',
    'Asset 20@0.5x',
    'Asset 21@0.5x',
    'Asset 22@0.5x',
    'Asset 23@0.5x',
    'Asset 24@0.5x',
    'Asset 25@0.5x',
];

let ICONES_IMAGES = {};

['Blanc', 'Bleu', 'Noir'].forEach(c => {
    ICONES_IMAGES[c] = {};
    ['PNG', 'SVG'].forEach(f => {
        ICONES_IMAGES[c][f] = {};
        ICONES_STRING.forEach(i => ICONES_IMAGES[c][f][i] = `./assets/icones/${c}/${f}/${i}.${f.toLocaleLowerCase()}`)
    });
});

export { ICONES_STRING, ICONES_IMAGES };
