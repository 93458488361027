import { get, getEntity } from './api';

const BASE_URL = '/partenaires';

export default {
  getType(type) {
    return get(BASE_URL, {
      params: {
        'filter[type][op]': '=',
        'filter[type][value]': type,
        'sort[created_at]': 'ASC'
      }
    });
  },

  all() {
    return get(BASE_URL, {
      params: {
        'sort[created_at]': 'ASC'
      }
    });
  }
};
