import React, { useState } from 'react';
import { Col, FormControl, InputGroup, Nav, Row } from 'react-bootstrap';
import DepartmentSelectorTab from './DepartmentSelectorTab'
import CommuneSelectorTab from './CommuneSelectorTab';
import ArrondissementSelectorTab from './ArrondissementSelectorTab';
import VillageSelectorTab from './VillageSelectorTab';
import { SearchBoxLight } from '../SearchBoxLight';
import * as queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function AreaSelector() {
  const [selectedTab, setSelectedTab] = useState('departements')
  const [query, setQuery] = useState('')
  const history = useHistory();

  const handleTabSelect = (eventKey) => setSelectedTab(eventKey);

  const handleQueryChanged = (val) => {
    setQuery(val)
  }

  function handleAreaSelected(area) {
    let parsed = queryString.parse(window.location.search);

    if(parsed.indicateur) {
      history.push('/analyse?' + queryString.stringify({
        ...parsed,
        area_type: selectedTab,
        area_id: area.id
      }));

      ReactGA.event({
      category: 'Zone géographique',
      action: area.name
      });

    } else {
      history.push('/indicateurs?' + queryString.stringify({
        ...parsed,
        area_type: selectedTab,
        area_id: area.id
      }));

      ReactGA.event({
      category: 'Zone géographique',
      action: area.name
      });

    }
  }

  return (
    <div className="area-selector shadow rounded">
      <div className="content">
        <Row>
          <Col sm={8}>
            <Nav defaultActiveKey={selectedTab} onSelect={handleTabSelect} className="custom-navs area-types-nav">
              <Nav.Item>
                <Nav.Link eventKey="departements">Département</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="communes">Commune</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="arrondissements">Arrondissement</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="villages">Village</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={4}>
            <SearchBoxLight handleQueryChanged={handleQueryChanged}></SearchBoxLight>
          </Col>
        </Row>

        <hr/>

        {
          (selectedTab == 'departements')
          && (
            <DepartmentSelectorTab handleDepartementSelected={handleAreaSelected} query={query}></DepartmentSelectorTab>)
        }
        {
          (selectedTab == 'communes')
          && (
            <CommuneSelectorTab handleCommuneSelected={handleAreaSelected} query={query}></CommuneSelectorTab>)
        }
        {
          (selectedTab == 'arrondissements')
          && (
            <ArrondissementSelectorTab handleArrondissementSelected={handleAreaSelected} query={query}></ArrondissementSelectorTab>)
        }
        {
          (selectedTab == 'villages')
          && (
            <VillageSelectorTab handleVillageSelected={handleAreaSelected} query={query}></VillageSelectorTab>)
        }
      </div>
    </div>
  );
}
