import React, { useState } from "react";
import { InputGroup, Button } from "react-bootstrap";
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead-bs4.css';

export function Search() {
  const [selected, setSelected] = useState([]);
  const options = ['eau', 'ressources']

  return (
    <InputGroup size="md" className="search">
      <InputGroup.Prepend>
        <InputGroup.Text id="basic-addon1">
          <i className="fas fa-search"></i>
        </InputGroup.Text>
      </InputGroup.Prepend>
      <Typeahead
        labelKey="thematique"
        multiple={true}
        onChange={setSelected}
        options={options}
        placeholder="Trouver une information"
        selected={selected}
      />
      <InputGroup.Append>
        <Button variant="outline-secondary">
          <i className="fas fa-chevron-down"></i>
        </Button>
      </InputGroup.Append>
    </InputGroup>
  );
};
