import React from "react";
import { Row, Col  } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { SearchBox } from '../SearchBox';

export function HomeSearch() {

  return (
    <div className="home-search container">
        <Row className="justify-content-center">
          <Col md={6}>
            <h2 className="mt-3 text-center title-bordered">Toutes les données sur l'eau</h2>

            <div className="mt-4 mb-3">
              <SearchBox />
            </div>

            <p className="text-center">
              Rechercher par { ' ' }
              <Link to="/geographies">départements</Link> { ' ' }
              ou par { ' ' }
              <Link to="/indicateurs">indicateurs</Link>
            </p>
          </Col>
        </Row>
    </div>
  );
};
