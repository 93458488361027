import React from "react";
import {Container, Card, Row, Col, Alert} from 'react-bootstrap';
import '../styles/Banner/RapportInfoBanner.css';
import classnames from 'classnames';
import {RapportInfoBanner} from '../components/Banners/RapportInfoBanner';
import { postEntity } from "../services/api";
import BackToTop from '../components/BackToTop';

export class Suggestion extends React.Component {
  state = {
    nom: '',
    email: '',
    message: '',
    loading: true,
    alert: null,
    disable: ""
  };

   reset = () => {
    document.getElementById("contact-form").reset();
   }

  submit(e) {
    e.preventDefault();
    this.setState({disable: "disabled"})
    postEntity('/sugggestions', {
      nom: this.state.nom,
      email: this.state.email,
      message: this.state.message,
    }).then(() => {
      this.setState({ alert: { success: true, message: 'Votre suggestion a été prise en compte'} });
      // this.reset();
      // this.setState({disable:""})
    }, () => {
      this.setState({ alert: { success: false, message: 'Erreur loes de l\'envoie'} });
    }).finally(() => {
      setTimeout(() => {
        this.setState({ alert: null });
        this.reset();
        this.setState({disable:""})
      }, 5000);
    });
  }

  render() {
    return (
      <div className="Background pb-4 statique-page container-fluid">
        <RapportInfoBanner title="Faites nous parvenir vos suggestions"/>

        <Container className="text-form display-flex-center page-body">
          <Card className="shadow p-3 mb-5 bg-white rounded">

            <Card.Body>
              <Row className="justify-content-center">
                <Col md={8}>
                  {
                    this.state.alert && <Alert variant={this.state.alert.success ? 'success' : 'danger'}>
                    { this.state.alert.message }
                  </Alert>
                  }

                  <form id="contact-form" onSubmit={(e) => this.submit(e)}>
                    <div className="form-group">
                      <label htmlFor="nom">Nom et prénom</label>
                      <input type="text" required className="form-control form-control-lg" id="nom"
                        onInput={(e) => this.setState({ nom: e.target.value })} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <input type="email" required className="form-control form-control-lg" id="email"
                        onInput={(e) => this.setState({ email: e.target.value })} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea className="form-control form-control-lg" id="message" required
                        onInput={(e) => this.setState({ message: e.target.value })}></textarea>
                    </div>

                    <small className="mb-3">Veuillez consulter la rubrique <a href="https://ressources-eau.gouv.bj/pages/49-mentions-legales">"Mentions légales"</a>  pour toute information relative aux données à caractère personnel.</small>
                    <br />
                    {
                      this.state.disable == "disabled" ? <button disabled type="submit" className="btn btn-primary mt-3">En cours ...</button> :
                      <button type="submit" className="btn btn-primary mt-3">Soumettre</button>
                    }
                    
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
        <BackToTop/>
      </div>
    );
  }
}
