import { get } from './api';
import QueryStringService from './QueryStringService';

const BASE_URL = '/departements';

export default {
  getAll() {
    return this.getList(true,
      null,
      {},
      [],
      {
        name: 'asc'
      })
  },

  getList(all = false, perPage = 15, filter = {}, include = [], sort = {}) {
    let params = QueryStringService.build({
      filter, include, sort
    }, all, perPage)

    return get(BASE_URL, {
      params
    });
  }
};
