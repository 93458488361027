import { get, post } from './api';
import QueryStringService from './QueryStringService';

const BASE_URL = 'actualites';

export default {
  getMains(amount = 20) {
    return this.getList(
      null,
      amount,
      {accueil: true},
      ['image'],
      {
        created_at: 'desc'
      }
    );
  },

  async getRecents(amount = 4) {
    return this.getList(
      null,
      amount,
      {},
      [],
      {
        updated_at: 'desc'
      }
    );
  },


  getList(all = false, perPage = 15, filter = {}, include = [], sort = {}) {
    let params = QueryStringService.build({
      filter, include, sort
    }, all, perPage)

    return get(BASE_URL, {
      params
    });
  },
};
