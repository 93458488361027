import * as moment from 'moment';


const DATE_GROUPINGS = ['year', 'month', 'day'];
const GEO_GROUPINGS = ['departements', 'communes', 'arrondissements'];

export default {
  formatDateForChartGrouping(rawDate, chartGrouping) {
    const date = moment(rawDate);

    switch (chartGrouping) {
      case 'month':
        return date.format('YYYY-MM');
      case 'day':
        return date.format('YYYY-MM-DD')
      default:
        return date.format('YYYY');
    }
  },
  toBarData(responseData, title, chartGrouping) {
    const barValue = responseData.map(v => v.value);
    const barKey = responseData.map(k => {
      if(!chartGrouping || DATE_GROUPINGS.includes(chartGrouping)) {
        return this.formatDateForChartGrouping(k.key, chartGrouping)
      } else if(GEO_GROUPINGS.includes(chartGrouping)) {
        return k.key
      }
    });

    return {
      labels: barKey,
      datasets: [{
        label: title,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgb(255, 99, 132)',
        fill: false,
        data: barValue,
      }]
    };
  },
  getTableColumns(data, analyseType, chartGrouping, mapGrouping, indicateur) {
    const ignoredFields = ['couleur', 'geo'];

    const barChartKeyFormatter = (cell, row) => {
      if(!chartGrouping || DATE_GROUPINGS.includes(chartGrouping)) {
        return this.formatDateForChartGrouping(cell, chartGrouping)
      } else if(GEO_GROUPINGS.includes(chartGrouping)) {
        return cell
      }
    };

    let tableColumns = [];
    if (data && data.length > 0) {
      tableColumns = Object.keys(data[0]).reduce((acc, field) => {
        if (ignoredFields.includes(field)) {
          return acc;
        }

        if (field === 'key') {
          let text = 'PERIODE';

          if (GEO_GROUPINGS.includes(chartGrouping)) {
            text = chartGrouping.toUpperCase();
          }

          if(analyseType === 'map' && !mapGrouping) {
            text = 'LON, LAT'
          }

          if(analyseType === 'map' && mapGrouping) {
            text = mapGrouping.toUpperCase();
          }

          acc = [{
            dataField: field,
            text,
            formatter: analyseType !== 'map' ? barChartKeyFormatter : null
          }, ...acc]

          return acc;
        }

        if (field == 'value') {
          acc.push({
            dataField: field,
            text: indicateur.unite ? `VALEUR (${indicateur.unite})` : `VALEUR`
          });

          return acc;
        }


        acc.push({
          dataField: field,
          text: field.toUpperCase()
        })

        return acc;
      }, []);
    }

    return tableColumns;
  },

  getTableData(data, analyseType, mapGrouping)  {
    let tableData = data;

    if(analyseType == 'map'
      && mapGrouping === null
      && data.length > 0
      && !data[0].hasOwnProperty(('key'))
    ) {
      tableData = data.map((item, index) => {
        return {
          ...item,
          key: item.geo && item.geo.type === 'Point' ? item.geo.coordinates.join(', ') : index
        }
      })
    }

    return tableData
  }
};
