import React from 'react';
import {
    Card,
    Col,
    Container,
    ListGroup,
    ListGroupItem,
    Nav,
    Row,
    Form,
    Accordion,
    Button,
    Table
} from 'react-bootstrap';
import '../components/guidePages/style.css';

import Prerequis from '../components/guidePages/prerequis';
import DataAnalyse from '../components/guidePages/dataAnalyse';
import Acceder from '../components/guidePages/accceder';
import Section from '../components/guidePages/section';
import Footer from '../components/guidePages/footer';
import BackToTop from '../components/BackToTop';
import Pdf from '../static/guide_dutilisation_du_frontend_web.pdf';

export class Guide extends React.Component {

    render() {
        return (
            <div>

                <h1 className="text-center mt-5 mb-5">Guide d'utilisation dela plateforme de digitalisation de l'eau au Bénin</h1>
                <Container>
                    <p><a href={Pdf}><i class="fas fa-file-pdf"></i> Télécharger le guide</a></p>
                    <Table striped bordered hover className="col-8">
                        <tr>
                            <td><b>Date de mise à jour :</b></td>
                            <td>Juillet 2020</td>
                        </tr>
                    </Table>
                    <Accordion defaultActiveKey="0">
                        <Card>
                            <Card.Header style={{ backgroundColor: '#0A3764' }}>
                                <Accordion.Toggle as={Button} variant="link" eventKey="0" >
                                    <h3 className="text-white"> I. Prérequis</h3>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="0">
                                <Card.Body><Prerequis /></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header style={{ backgroundColor: '#0A3764' }}>
                                <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                    <h3 className="text-white">II. Accéder à la plateforme</h3>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                                <Card.Body><Acceder /></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header style={{ backgroundColor: '#0A3764' }}>
                                <Accordion.Toggle as={Button} variant="link" eventKey="2">
                                    <h3 className="text-white">III. Faire une analyse</h3>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="2">
                                <Card.Body><DataAnalyse /></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header style={{ backgroundColor: '#0A3764' }}>
                                <Accordion.Toggle as={Button} variant="link" eventKey="3">
                                    <h3 className="text-white">IV. Les sections de la page d'accueil</h3>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="3">
                                <Card.Body><Section /></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        <Card>
                            <Card.Header style={{ backgroundColor: '#0A3764' }}>
                                <Accordion.Toggle as={Button} variant="link" eventKey="4">
                                    <h3 className="text-white">V. Le pied de page</h3>
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="4">
                                <Card.Body><Footer /></Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    </Accordion>
                    <BackToTop />
                </Container>

            </div>

        );
    }
}
