import * as flatten from 'flat';

export default {
  serialize(params) {
    let res = {};

    for (let [key, val] of Object.entries(flatten(params))) {
      if(typeof val == 'object' && Object.keys(val).length === 0) {
        continue
      }

      let fixedKey = key.replace(/\./g, '][') + ']'
      fixedKey = fixedKey.replace(']', '')
      res[fixedKey] = val
    }

    return res
  },

  build(params, all = false, perPage = 15) {
    let res = this.serialize(params)

    if (all) {
      res['all'] = true
    } else {
      res['per_page'] = perPage
    }

    return res;
  }
};
