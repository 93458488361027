import React from "react";
import BackToTop from '../components/BackToTop';

import {
  Col,
  Container,
  Row
} from "react-bootstrap";
import { Link } from "react-router-dom";

import { RapportInfoBanner } from "../components/Banners/RapportInfoBanner";
import { RapportSimilaireBlock } from "../components/RapportSimilaireBlock";
import { Search } from "../components/SearchField";

export function Informations () {
  return (
    <div className="Background">

      <RapportInfoBanner/>

      <Container>
        <Row>
          <Col md={{ span: 3, offset: 9 }}>
            <p className="mt-5"><Link>Tous les rapports</Link><Link className="ml-5">Catégories</Link></p>
          </Col>
          <Col className="mt-5 mb-5 pb-5" md={{ offset: 4, span: 4 }}>
            <Search/>
          </Col>
        </Row>

        <RapportSimilaireBlock/>

      </Container>
      <BackToTop/>
    </div>
  );
}
